export default defineNuxtPlugin(() => {
  const getVideoId = (url: string): string | undefined => {
    const youtubeRegex =
      /^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]{7,15})(?:[\?&][a-zA-Z0-9\_-]+=[a-zA-Z0-9\_-]+)*$/;

    if (!url) {
      return;
    }

    const matches = url.match(youtubeRegex);

    if (matches) {
      return matches[1];
    }
  };

  const getEmbedUrl = (url: string): string | false => {
    const id = getVideoId(url);

    if (id) {
      return `https://www.youtube.com/embed/${id}`;
    } else {
      return false;
    }
  };

  return {
    provide: {
      getVideoId,
      getEmbedUrl,
    },
  };
});
