import { createQueryKeys } from '@lukemorales/query-key-factory';

const useThreadsQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('threads', {
    all: (params = {} as Ref, returns = 'threads') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.threads
          .getAll({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    byUuid: (uuid, params = {} as Ref, returns = 'thread') => ({
      queryKey: [uuid, params, returns],
      queryFn: (context) =>
        $api.threads
          .getByUuid({
            uuid: toValue(uuid),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useThreadsQueries };
