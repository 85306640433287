import { object, string } from 'yup';

const forgotPasswordFields = {
  email: string,
};

const forgotPasswordSchema = object({
  email: string()
    .email('Please enter a valid email address')
    .required('This field is required'),
});

export { forgotPasswordFields, forgotPasswordSchema };
