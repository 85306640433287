<script setup lang="ts">
const { alert } = storeToRefs(useAlertStore());

const clearAlert = () => (alert.value = '');
</script>

<template>
  <HayModal @close="clearAlert">
    <div class="hay-alert">
      {{ alert }}
      <div class="hay-alert__progress" @animationend="clearAlert" />
    </div>
  </HayModal>
</template>

<style scoped lang="scss">
.hay-alert {
  text-align: center;

  background: color(neutral, 950, 0.9);
  border: 1px solid color(primary, 350);
  border-radius: 16px;
  color: color(primary, 350);

  max-width: 275px;
  padding: 32px 48px 48px 32px;
  width: calc(100vw - 64px);

  line-height: 1.3;
  &__progress {
    height: 4px;

    overflow: hidden;

    bottom: 16px;
    left: 32px;
    position: absolute;
    right: 32px;
    &:before,
    &:after {
      content: '';

      height: 100%;
      width: 100%;

      display: block;

      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:before {
      background-color: #333333;
    }
    &:after {
      animation-duration: 3s;
      animation-name: slideOut;
      animation-timing-function: linear;

      background-color: color(primary, 350);

      animation-fill-mode: forwards;
    }
  }

  &__close {
    cursor: pointer;

    position: absolute;
    right: 16px;
    top: 20px;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}
</style>
