import { createQueryKeys } from '@lukemorales/query-key-factory';

const useMyPostsQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('myPosts', {
    all: (params = {} as Ref, returns = 'posts') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.myPosts
          .getAll({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    byUuid: (uuid, params = {} as Ref, returns = 'post') => ({
      queryKey: [uuid, params, returns],
      queryFn: (context) =>
        $api.myPosts
          .getByUuid({
            uuid: toValue(uuid),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useMyPostsQueries };
