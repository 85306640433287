import isPlainObject from 'lodash/isPlainObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

const mapKeysDeep = (obj: Record<string, any>, fn: Function): any => {
  if (isPlainObject(obj)) {
    const objWithTransformedKeys = mapKeys(obj, (_, key) => fn(key));
    return mapValues(objWithTransformedKeys, (value) => mapKeysDeep(value, fn));
  } else if (isArray(obj)) {
    return map(obj, (value) => mapKeysDeep(value, fn));
  } else {
    return obj;
  }
};

const camelizeKeys = (obj: Record<string, any>) =>
  mapKeysDeep(obj, (key: string) => camelCase(key));

const decamelizeKeys = (obj: Record<string, any>) =>
  mapKeysDeep(obj, (key: string) => (key === '_method' ? key : snakeCase(key)));

export { camelizeKeys, decamelizeKeys };
