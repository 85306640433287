export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      buildClass: (child?: string) => {
        const route = useRoute();

        let classname = String(route.name) + '-page';

        if (child) {
          classname += `__${child}`;
        }

        return classname;
      },

      uniqueRouteName: () => {
        const route = useRoute();

        const page = computed(() => route.query.page || 1);

        return `${String(route.name)}-${page.value}`;
      },
    },
  };
});
