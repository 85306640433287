<script setup lang="ts">
const props = defineProps<{
  type: string;
  modelValue: string | number | null;
  name: string;
  id: string;
  placeholder?: string;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', modelValue: string | number | null): void;
}>();

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
</script>

<template>
  <input
    :id="id"
    v-model="value"
    :type="type"
    :name="name"
    :placeholder="placeholder"
    class="hay-form-field-input"
  />
</template>

<style scoped lang="scss">
.hay-form-field-input {
  transition: 300ms ease color;

  background: none;
  border: none;
  color: var(--field-text-color, color(neutral, 400));
  outline: none !important;

  height: 48px;
  padding: 0 var(--field-horizontal-padding, 16px);
  width: 100%;

  position: relative;
  z-index: 1;

  font-size: var(--field-font-size, 14px);
  font-weight: var(--field-font-weight);
  line-height: 48px;

  -moz-appearance: textfield;
  &:-webkit-outer-spin-button,
  &:-webkit-inner-spin-button {
    margin: 0;

    -webkit-appearance: none;
  }
  &:focus {
    color: var(--field-text-color, color(neutral, 100));
  }
}
</style>
