<script setup lang="ts"></script>

<template>
  <div class="image-overlay-content"><slot /></div>
</template>

<style scoped lang="scss">
.image-overlay-content {
  background: rgba(color(neutral, 800), 0.75);
  border-radius: 15px;

  height: 30px;
  min-width: 30px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}
</style>
