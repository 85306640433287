import HttpFactory from '@/repository/factory';

class PagesModule extends HttpFactory {
  private BASEURL = 'https://haystack-content-api-staging.agriland.workers.dev';

  async getBySlug({ slug = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${slug}`;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }
}

export { PagesModule };
