import * as Sentry from '@sentry/vue';

type SentryEnv = {
  dsn: string;
  enabled: boolean;
  environment: string;
  tracesSampleRate: number;
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
};

async function lazyLoadSentryIntegrations() {
  if (!import.meta.client) return;

  const { Replay } = await import('@sentry/vue');

  Sentry.addIntegration(
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    })
  );
}

function getSentryIntegrations() {
  if (!import.meta.client) return [];

  const router = useRouter();
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  });

  return [browserTracing];
}

export default defineNuxtPlugin((nuxtApp) => {
  const sentry = useEnv<SentryEnv>('SENTRY');

  if (!([true, 'true'].includes(sentry.enabled) && sentry.dsn)) {
    return;
  }

  const {
    dsn,
    environment,
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  } = sentry;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn,
    environment,
    integrations: getSentryIntegrations(),
    // Performance Monitoring
    tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  });

  // Lazy-load the replay integration to reduce bundle size
  lazyLoadSentryIntegrations();
});
