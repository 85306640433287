import { useRouter } from 'vue-router';

import { App, URLOpenListenerEvent } from '@capacitor/app';

/**
 * Intercepts URLs passed when the app is opened (i.e. push notifications) and decides how to handle them
 */

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();

  /**
   * Converts a URL to a Vue Router route object.
   *
   * @param {string} url - The URL to be converted.
   * @returns {object} - A Vue Router route object representing the URL.
   */
  const urlToRoute = (url) => {
    const path = new URL(url).pathname;
    const query = [...new URLSearchParams(url.split('?')[1])].reduce(
      (a, [k, v]) => ((a[k] = v), a),
      {}
    );

    return {
      path,
      ...(!!Object.keys(query).length && { query }),
    };
  };

  /**
   * Event handler; opens a event URL
   * @param {URLOpenListenerEvent} event
   * @returns void
   */
  const checkForUniversalLink = (event: URLOpenListenerEvent): void => {
    // Convert the incoming URL to a Vue Router route and navigate to it
    router.push(urlToRoute(event.url));
  };

  nuxtApp.hook('app:mounted', () => {
    App.addListener('appUrlOpen', checkForUniversalLink);
  });
});
