import { createQueryKeys } from '@lukemorales/query-key-factory';

const usePagesQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('pages', {
    bySlug: (slug, params = {} as Ref) => ({
      queryKey: [slug, params],
      queryFn: (context) =>
        $api.pages
          .getBySlug({
            slug: toValue(slug),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data)),
    }),
  });
};

export { usePagesQueries };
