import { Preferences } from '@capacitor/preferences';

const usePreferenceStore = defineStore('preference', () => {
  const preferences: Ref<Record<string, any>> = ref({
    preferredLayout: 'grid',
  });

  const getPreferences = () => {
    if (process.server) {
      return;
    }

    Object.keys(preferences.value).forEach(async (key) => {
      const preference = await Preferences.get({ key });

      if (preference.value) {
        preferences.value[key] = JSON.parse(preference.value);
      }
    });
  };

  const setPreference = async (key: string, value: any) => {
    preferences.value[key] = value;

    await Preferences.set({
      key,
      value: JSON.stringify(value),
    });
  };

  const togglePreferredLayout = () => {
    setPreference(
      'preferredLayout',
      preferences.value.preferredLayout === 'list' ? 'grid' : 'list'
    );
  };

  return { preferences, getPreferences, setPreference, togglePreferredLayout };
});

export { usePreferenceStore };
