<script setup lang="ts">
import HayIcon from '@/components/ui/HayIcon.vue';

import { useSlots } from 'vue';

const props = defineProps<{
  icon?: string;
  to?: string | Record<string, string>;
}>();

const slots = useSlots();

const route = useRoute();

const children = {
  index: [
    'category-for-sale-slug',
    'category',
    'for-sale',
    'search',
    'stockyard-slug',
  ],
  'dashboard-my-messages': [
    'dashboard-my-messages-uuid',
    'dashboard-my-messages',
  ],
  'dashboard-my-adverts-new': ['dashboard-my-adverts-new'],
  dashboard: [
    'dashboard-my-adverts',
    'dashboard-my-adverts-uuid-edit',
    'dashboard-my-adverts-uuid-preview',
    'dashboard-my-favourites',
    'dashboard-my-profile',
    'dashboard-payment-history',
    'dashboard-saved-searches',
    'dashboard',
  ],
};
</script>

<template>
  <router-link
    :to="to"
    :class="
      children[to.name].indexOf(route.name) >= 0
        ? 'tab-button--active'
        : 'tab-button'
    "
  >
    <div class="tab-button__icon">
      <BaseSvgIcon :is="icon" v-if="icon" type="mdi" />
      <slot v-if="slots.icon" name="icon" />
    </div>
    <div class="tab-button__label">
      <slot />
    </div>
  </router-link>
</template>

<style scoped lang="scss">
.tab-button {
  text-decoration: none;

  color: color(neutral, 700);

  padding-bottom: 15px;

  border-right: 1px rgba(0, 0, 0, 0.1) solid;

  display: flex;
  flex-direction: column;

  font-size: 12px;

  justify-content: center;
  align-items: center;
  &__label {
    font-weight: $medium;
  }
  &--active {
    background: color(primary, 550, 0.3);
    color: color(primary, 650);

    @extend .tab-button;
  }

  &:last-of-type{
    border-right: 0;
  }
}
</style>
