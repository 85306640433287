import { createQueryKeys } from '@lukemorales/query-key-factory';

const useFiltersQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('filters', {
    byCategory: (category, params = {} as Ref, returns = 'filters') => ({
      queryKey: [category, params, returns],
      queryFn: (context) =>
        $api.filters
          .getBySlug({
            slug: toValue(category),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useFiltersQueries };
