import HttpFactory from '@/repository/factory';

class NotificationTypesModule extends HttpFactory {
  private ENDPOINT = useEnv('CF_SEARCH_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'notification-types';

  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async getAll({ params = {}, options: opts = {} } = {}) {
    const url = this.BASEURL;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }
}

export { NotificationTypesModule };
