import { object, string, bool, ref } from 'yup';

const registrationSchema = object({
  name: string().required('This field is required'),
  email: string()
    .required('This field is required')
    .email('Please enter a valid email address'),
  password: string()
    .required('This field is required')
    .matches(/[0-9]/, 'The password must contain at least one number.')
    .matches(
      /[a-z]/,
      'The password must contain at least one lowercase letter.'
    )
    .matches(
      /[A-Z]/,
      'The password must contain at least one uppercase letter.'
    )
    .matches(/[^\w]/, 'The password must contain at least one symbol.'),
  passwordConfirmation: string()
    .required('This field is required')
    .oneOf([ref('password')], 'Passwords must match'),
  agreeTerms: bool()
    .required('You must agree to our terms to use this site')
    .oneOf([true], 'You must agree to our terms to use this site'),
});

export { registrationSchema };
