import { createQueryKeys } from '@lukemorales/query-key-factory';

const usePostsQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('posts', {
    all: (params = {} as Ref, returns = 'posts') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.posts
          .getAll({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    count: (params = {} as Ref, returns = 'counts') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.posts
          .getCount({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    bySlug: (slug, params = {} as Ref, returns = 'post') => ({
      queryKey: [slug, params, returns],
      queryFn: (context) =>
        $api.posts
          .getBySlug({
            slug: toValue(slug),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    byCompany: (slug, params = {} as Ref, returns = 'posts') => ({
      queryKey: [slug, params, returns],
      queryFn: (context) =>
        $api.posts
          .getByCompany({
            slug: toValue(slug),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    recent: (params = {} as Ref, returns = 'posts') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.posts
          .getRecent({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { usePostsQueries };
