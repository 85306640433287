<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: string | number | string[] | null | undefined;
    name: string;
    id: string;
    options: Record<string, string | number>[];
    placeholder?: string;
    valueProperty: string;
    textProperty: string;
  }>(),
  {
    modelValue: null,
    prefix: '',
    placeholder: '',
    valueProperty: 'name',
    textProperty: 'value',
  }
);

const emit = defineEmits<{
  (
    event: 'update:modelValue',
    value: string | number | string[] | undefined
  ): void;
}>();

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
</script>

<template>
  <div class="hay-form-field-select">
    <select :id="id" v-model="value" :name="name">
      <option v-if="placeholder" :value="null" selected disabled>
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option[valueProperty]"
        :value="option[valueProperty]"
      >
        {{ option[textProperty] }}
      </option>
    </select>
  </div>
</template>

<style scoped lang="scss">
.hay-form-field-select {
  select {
    background-color: var(--field-background-color, color(neutral, 850));
    border: none;
    color: var(--field-text-color, color(neutral, 400));
    outline: none !important;

    height: 48px;
    padding: 0 var(--field-horizontal-padding, 16px);
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    position: relative;
    z-index: 1;

    font-size: var(--field-font-size, 14px);
    font-weight: var(--field-font-weight);
    line-height: 48px;
  }
  position: relative;
  &:after {
    content: '';
    transform: translateY(-50%);

    border-color: color(neutral, 400) transparent transparent transparent;
    border-style: solid;
    border-width: 8px 7px 0 7px;

    height: 0;
    width: 0;

    position: absolute;
    right: 15px;
    top: 50%;
    z-index: 1;
  }
}
</style>
