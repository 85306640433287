import { v4 as uuidv4 } from 'uuid';

const useSessionId = async () => {
  const cookie = await useCookie('x-agriland-session-id');

  cookie.value = cookie.value || uuidv4();

  return cookie.value;
};

export { useSessionId };
