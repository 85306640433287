<script setup lang="ts">
const { isApp } = await useDeviceType();

const showFooter = computed(() => !isApp);
const showTabs = computed(() => isApp);
</script>

<template>
  <div :class="`the-app${isApp ? '--app' : ''}`">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.the-app {
  $this: &;

  @include media-breakpoint-up(md) {
    padding-top: calc(var(--hay-safe-area-top) + 64px);
  }

  background: color(neutral, 250);

  padding-top: calc(var(--hay-safe-area-top) + 52px);
  &--app {
    padding-bottom: 72px;

    @extend .the-app;
  }
}
</style>
