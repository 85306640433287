import { useQueryClient } from '@tanstack/vue-query';

const useThreadsMutations = () => {
  const { $api, $queries } = useNuxtApp();
  const queryClient = useQueryClient();

  const create = () => ({
    mutationFn: ({ post, content }) =>
      $api.threads
        .create({ uuid: toValue(post).uuid, content: toValue(content) })
        .then((resp) => resp.data),
  });

  const reply = () => ({
    mutationFn: ({ uuid, content }) =>
      $api.threads
        .reply({ uuid: toValue(uuid), content: toValue(content) })
        .then((resp) => (uuid, resp.data)),
    onMutate: async ({ uuid, content }) => {
      const { queryKey } = $queries().threads.byUuid(uuid);

      await queryClient.cancelQueries({ queryKey });

      const oldValue = queryClient.getQueryData(queryKey);

      const date = new Date();
      const createdAt = Math.round(date.getTime() / 1000);

      queryClient.setQueryData(queryKey, () => ({
        ...oldValue,
        messages: [...oldValue.messages, { type: 'sent', createdAt, content }],
      }));

      return { oldValue };
    },
    onError: (_error, { uuid, content }, context) => {
      const { thread } = context;

      queryClient.setQueryData(queryKey, thread);
    },
    onSettled: (_data, _error, { uuid, content }, _context) => {
      const { queryKey } = $queries().threads.byUuid(uuid);

      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  return { create, reply };
};

export { useThreadsMutations };
