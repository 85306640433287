import { createQueryKeys } from '@lukemorales/query-key-factory';

const useFavouritesQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('favourites', {
    all: (params = {} as Ref, returns = 'posts') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.favourites
          .getAll({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    allMeta: (params = {} as Ref, returns = 'meta') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.favourites
          .getAll({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useFavouritesQueries };
