<script setup lang="ts"></script>

<template>
  <div class="hay-form-row">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.hay-form-row {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  align-items: flex-end;
  & + & {
    margin-top: var(--row-spacing, 24px);
  }
  &:deep(> *) {
    margin-left: 8px !important;
    margin-right: 8px !important;

    flex: var(--field-width, 1);
  }
}
</style>
