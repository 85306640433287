interface Modules {
  categories: InstanceType<typeof CategoriesModule>;
  clicks: InstanceType<typeof ClicksModule>;
  companies: InstanceType<typeof CompaniesModule>;
  counties: InstanceType<typeof CountiesModule>;
  favourites: InstanceType<typeof FavouritesModule>;
  fields: InstanceType<typeof FieldsModule>;
  filters: InstanceType<typeof FiltersModule>;
  myPosts: InstanceType<typeof MyPostsModule>;
  notifications: InstanceType<typeof NotificationsModule>;
  notificationTypes: InstanceType<typeof NotificationTypesModule>;
  pages: InstanceType<typeof PagesModule>;
  paymentLinks: InstanceType<typeof PaymentLinksModule>;
  posts: InstanceType<typeof PostsModule>;
  profile: InstanceType<typeof ProfileModule>;
  threads: InstanceType<typeof ThreadsModule>;
  user: InstanceType<typeof UserModule>;
}

type Interceptors = {
  request: Interceptor[];
  response: Interceptor[];
};

type InterceptorFunctions = {
  request: {
    use: Function;
  };
  response: {
    use: Function;
  };
};

type Interceptor = {
  success?: Function;
  error?: Function;
};

type Api = Modules & {
  interceptors: InterceptorFunctions;
};

export default defineNuxtPlugin((nuxtApp) => {
  const interceptors: Interceptors = {
    request: [],
    response: [],
  };

  const modules: Modules = {
    categories: new CategoriesModule(interceptors),
    clicks: new ClicksModule(interceptors),
    companies: new CompaniesModule(interceptors),
    counties: new CountiesModule(interceptors),
    favourites: new FavouritesModule(interceptors),
    fields: new FieldsModule(interceptors),
    filters: new FiltersModule(interceptors),
    myPosts: new MyPostsModule(interceptors),
    notifications: new NotificationsModule(interceptors),
    notificationTypes: new NotificationTypesModule(interceptors),
    pages: new PagesModule(interceptors),
    paymentLinks: new PaymentLinksModule(interceptors),
    profile: new ProfileModule(interceptors),
    posts: new PostsModule(interceptors),
    threads: new ThreadsModule(interceptors),
    user: new UserModule(interceptors),
  };

  const api: Api = {
    ...modules,
    interceptors: {
      request: {
        use: (interceptor: Interceptor) =>
          interceptors.request.push(interceptor),
      },
      response: {
        use: (interceptor: Interceptor) =>
          interceptors.response.push(interceptor),
      },
    },
  };

  return {
    provide: {
      api,
    },
  };
});
