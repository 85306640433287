import HttpFactory from '@/repository/factory';

import { type Profile } from '@/interfaces/User';

class ProfileModule extends HttpFactory {
  private ENDPOINT = useEnv('APP_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'user/profile';
  private AUTHENTICATED = true;
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async get({ params = {}, options: opts = {} } = {}) {
    const url = this.BASEURL;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async update({ profile = {}, params = {}, options: opts = {} } = {}) {
    const url = this.BASEURL;
    const method = 'POST';
    const data = { ...profile, _method: 'patch' };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }
}

export { ProfileModule };
