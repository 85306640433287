<script setup lang="ts">
const slots = useSlots();
</script>

<template>
  <div class="the-navbar">
    <div v-if="slots.start" class="the-navbar__slot--start">
      <slot name="start" />
    </div>
    <div class="the-navbar__slot--default">
      <slot />
    </div>
    <div v-if="slots.end" class="the-navbar__slot--end">
      <slot name="end" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.the-navbar {
  $this: &;

  @include container;
  @include media-breakpoint-up(md) {
    min-height: 64px;
  }

  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;

  display: grid;

  line-height: 0;

  align-items: center;
  grid-template-columns: [slotStart] auto [slotDefault] 1fr [slotEnd] auto;

  &__slot {
    &--start,
    &--default,
    &--end {
      @extend #{$this}__slot;
    }
    &--start {
      grid-column: slotStart;
    }
    &--default {
      display: flex;

      grid-column: slotDefault;
      justify-content: center;
    }
    &--end {
      grid-column: slotEnd;
    }
  }
}
</style>
