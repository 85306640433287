<script setup lang="ts">
import Search from '@/icons/ui/Search.vue';
import { mdiWindowClose } from '@mdi/js';

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const router = useRouter();

const terms = ref('');
const searchInput = ref(null);

const { logEvent } = useAnalytics();

const onSearch = () => {
  logEvent('search', { search_term: toValue(terms) });

  emit('close');

  router.push({ name: 'search', query: { terms: terms.value } });
};

const onSuggestion = (suggestion) => {
  emit('close');

  router.push({
    name: 'category',
    params: { category: suggestion.category.slug },
    query: { county: suggestion.location.slug },
  });
};

const onCancel = () => {
  emit('close');
};

const suggestions = [
  {
    category: {
      name: 'Tractors',
      slug: 'tractors',
    },
    location: {
      name: 'Kerry',
      slug: 'kerry',
    },
  },
  {
    category: {
      name: 'Combine harvesters',
      slug: 'combine-harvesters',
    },
    location: {
      name: 'Meath',
      slug: 'meath',
    },
  },
  {
    category: {
      name: 'Goats',
      slug: 'goats',
    },
    location: {
      name: 'Donegal',
      slug: 'donegal',
    },
  },
];

onMounted(() => {
  setTimeout(() => {
    if (searchInput.value) {
      searchInput.value.focus();
    }
  }, 400);
});
</script>

<template>
  <HayModal :show-close="false" @close="onCancel">
    <div class="search-box">
      <form class="search-box__form" @submit="onSearch">
        <BaseSvgIcon :is="Search" class="search-box__icon" />
        <input
          ref="searchInput"
          v-model="terms"
          type="text"
          placeholder="Search Haystack"
          class="search-box__input"
        />
        <BaseSvgIcon
          :is="mdiWindowClose"
          type="mdi"
          class="search-box__close"
          @click="onCancel"
        />
      </form>
      <!--div class="search-box__suggestions">
        <h3>Suggested searches</h3>
        <ul>
          <li v-for="(suggestion, index) in suggestions">
            <button @click="onSuggestion(suggestion)">
              <strong>{{ suggestion.category.name }}</strong>
              in
              <strong>{{ suggestion.location.name }}</strong>
            </button>
          </li>
        </ul>
      </div-->
    </div>
  </HayModal>
</template>

<style scoped lang="scss">
.search-box {
  background: color(primary, 600);

  max-width: 720px;
  padding: 24px;
  width: calc(100vw - 48px);

  &__form {
    position: relative;
  }
  &__icon,
  &__close {
    transform: translateY(-50%);

    color: color(neutral, 700);

    position: absolute;
    top: 50%;
  }
  &__icon {
    left: 10px;
  }

  &__input {
    transition: 300ms ease border-color, 300ms ease background-color;

    background-color: color(primary, 400);
    border: 1px solid color(primary, 400);
    border-radius: 8px;
    color: color(neutral, 700);
    outline: none !important;

    height: 52px;
    padding-left: 40px;
    padding-right: 16px;
    width: 100%;

    font-size: 18px;
    &:focus {
      background-color: color(primary, 300);
      border-color: color(primary, 400);
    }
  }
  &__close {
    cursor: pointer;

    right: 10px;
  }
  &__suggestions {
    margin-top: 32px;
    h3 {
      color: color(neutral, 800);

      font-size: 14px;
      font-weight: 500;
    }
    ul {
      margin: 0;
      padding: 0;

      list-style-type: none;
      li {
        & + li {
          margin-top: 8px;
        }
        button {
          cursor: pointer;
          text-align: left;

          background: none;
          border: none;
          color: color(neutral, 800);

          padding: 0;

          font-size: 18px;

          -webkit-appearance: none;
          strong {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
