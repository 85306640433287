export default defineNuxtPlugin(() => {
  return {
    provide: {
      mutations: () => {
        const clicks = useClicksMutations();
        const favourites = useFavouritesMutations();
        const myPosts = useMyPostsMutations();
        const notifications = useNotificationsMutations();
        const profile = useProfileMutations();
        const threads = useThreadsMutations();
        const user = useUserMutations();

        return {
          clicks,
          favourites,
          myPosts,
          notifications,
          profile,
          threads,
          user,
        };
      },
    },
  };
});
