<script setup lang="ts">
const slots = useSlots();
</script>

<template>
  <div class="hay-form-message">
    <div v-if="slots.start" class="hay-form-message__slot--start">
      <slot name="start" />
    </div>
    <div class="hay-form-message__slot--default">
      <slot />
    </div>
    <div v-if="slots.end" class="hay-form-message__slot--end">
      <slot name="end" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.hay-form-message {
  $this: &;

  text-align: center;

  color: color(neutral, 800);

  display: flex;

  font-size: 14px;

  justify-content: space-between;
  align-items: center;
  &__slot {
    &--default,
    &--start,
    &--end {
      @extend #{$this}__slot;
    }
    &--default,
    &--start {
      margin-right: auto;
    }
    &--default,
    &--end {
      margin-left: auto;
    }
  }
  a {
    cursor: pointer;

    color: currentColor;

    font-weight: $semi;
  }
}
</style>
