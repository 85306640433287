<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';

const { $queries } = useNuxtApp();

const {
  isLoading,
  data: posts,
  suspense: postsSuspense,
} = useQuery($queries().posts.recent({ perPage: 6 }));

onServerPrefetch(async () => {
  await postsSuspense();
});
</script>

<template>
  <PostList
    :posts="posts"
    :show-spotlight-badge="false"
    :show-business-card="false"
    :show-gallery-count="false"
    :show-favourite="false"
    :show-meta="false"
    :is-loading="isLoading"
    :per-row="6"
    class="recent-posts"
  />
</template>
