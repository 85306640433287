import HttpFactory from '@/repository/factory';

class FavouritesModule extends HttpFactory {
  private ENDPOINT = useEnv('APP_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'user/favourites';
  private AUTHENTICATED = true;
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async getAll({ params = {}, options: opts = {} } = {}) {
    const url = this.BASEURL;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async add({ uuid = '', params = {}, options: opts = {} }) {
    const url = this.BASEURL;
    const data = { uuid };
    const method = 'POST';
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, data, method, options, authenticated });
  }

  async remove({ uuid = '', params = {}, options: opts = {} }) {
    const url = this.BASEURL;
    const data = { _method: 'DELETE', uuid };
    const method = 'POST';
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, data, method, options, authenticated });
  }
}

export { FavouritesModule };
