import { RouteLocationNormalizedLoaded } from 'vue-router';

const useAuthStore = defineStore('auth', () => {
  const authenticated = computed(() => !!currentUser.value);
  const currentUser = ref(null);
  const nextRoute = ref<RouteLocationNormalizedLoaded | undefined>(undefined);
  const shouldRequestAuthentication = ref(false);
  const authenticationForm = ref('login');

  return {
    authenticated,
    currentUser,
    nextRoute,
    shouldRequestAuthentication,
    authenticationForm,
  };
});

export { useAuthStore };
