<script setup lang="ts">
const props = defineProps<{
  color?: string;
  to?: string | Record<string, string>;
  outline?: boolean;
}>();

const slots = useSlots();
</script>

<template>
  <component
    :is="to ? defineNuxtLink({}) : 'button'"
    class="hay-button"
    :class="color ? `hay-button--${color}${outline ? '-outline' : ''}` : ''"
    :to="to"
  >
    <div v-if="slots.iconStart" class="hay-button__slot--icon-start">
      <slot name="iconStart" />
    </div>
    <div v-if="slots.start" class="hay-button__slot--start">
      <slot name="start" />
    </div>
    <div class="hay-button__slot--default">
      <slot />
    </div>
    <div v-if="slots.end" class="hay-button__slot--end">
      <slot name="end" />
    </div>
    <div v-if="slots.iconEnd" class="hay-button__slot--icon-end">
      <slot name="iconEnd" />
    </div>
  </component>
</template>

<style scoped lang="scss">
.hay-button {
  $this: &;
  cursor: pointer;
  text-decoration: none;
  transition: 300ms ease all;
  white-space: nowrap;

  background-color: var(--button-background-color, none);
  border: var(--button-border, none);
  border-radius: var(--button-border-radius, 8px);
  color: var(--button-color, color(neutral, 850));

  width: var(--button-width, auto);
  height: var(--button-height, 48px);
  padding: var(--button-padding, 0);
  margin: var(--button-margin, 0);

  display: flex;

  font-size: var(--button-font-size, 16px);
  font-weight: var(--button-font-weight, $regular);
  line-height: 0;

  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  &--yellow {
    --button-background-color: #{color(primary, 550)};
    --button-hover-background-color: #{color(primary, 650)};
    --button-text-color: #{color(neutral, 850)};
    &-outline {
      @extend #{$this}--yellow;
    }
  }
  &--white {
    --button-background-color: #{color(neutral, 100)};
    --button-text-color: #{color(neutral, 850)};
    &-outline {
      background-color: transparent;
      border: 2px solid var(--button-background-color);
      color: var(--button-background-color);

      @extend #{$this}--white;
    }
  }
  &:hover {
    cursor: pointer;

    background-color: var(--button-hover-background-color, none);
    color: var(--button-hover-color, color(neutral, 850));
  }
  &:focus-within {
    background-color: var(--button-background-color, none);
    color: var(--button-color, color(neutral, 850));
  }
  &--disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
  &__slot {
    &--icon-start,
    &--start,
    &--end,
    &--icon-end {
      @extend #{$this}__slot;
    }
    &--icon-start {
      margin-right: 8px;
    }
    &--start {
      margin-right: 16px;
    }
    &--end {
      margin-left: 16px;
    }
    &--icon-end {
      margin-left: 8px;
    }
  }
}
</style>
