<script setup lang="ts">
const slots = useSlots();

const props = defineProps<{
  title?: string;
}>();
</script>

<template>
  <section class="page-section">
    <SectionTitle :title="title" class="page-section__title" />
    <slot />
  </section>
</template>

<style scoped lang="scss">
.page-section {
  &__title {
    --margin-bottom: 16px;
  }
}
</style>
