import { useQueryClient } from '@tanstack/vue-query';

const useMyPostsMutations = () => {
  const { $api } = useNuxtApp();
  const queryClient = useQueryClient();

  const create = () => ({
    mutationFn: ({ post }) =>
      $api.myPosts
        .create({
          post: {
            ...toValue(post),
            location: toValue(post).town,
          },
        })
        .then((resp) => resp.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
  });

  const update = () => ({
    mutationFn: ({ post }) =>
      $api.myPosts
        .update({
          post: {
            ...toValue(post),
            location: toValue(post).town,
          },
        })
        .then((resp) => resp.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
  });

  const publish = () => ({
    mutationFn: ({ uuid }) =>
      $api.myPosts.publish({ uuid }).then((resp) => resp.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
  });

  const sold = () => ({
    mutationFn: ({ uuid }) =>
      $api.myPosts.sold({ uuid }).then((resp) => resp.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
  });

  const withdraw = () => ({
    mutationFn: ({ uuid }) =>
      $api.myPosts.withdraw({ uuid }).then((resp) => resp.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
  });

  const relist = () => ({
    mutationFn: ({ uuid }) =>
      $api.myPosts.relist({ uuid }).then((resp) => resp.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
  });

  return { create, update, publish, sold, withdraw, relist };
};

export { useMyPostsMutations };
