<script setup lang="ts">
const slots = useSlots();

const props = defineProps<{
  title?: string;
}>();
</script>

<template>
  <h2 v-if="title || slots.title" class="section-title">
    {{ title }}
    <slot name="title" />
  </h2>
</template>

<style scoped lang="scss">
.section-title {
  letter-spacing: var(--letter-spacing, normal);
  text-align: var(--text-align, left);
  text-transform: var(--text-transform, none);

  color: var(--color, color(neutral, 750));

  margin-bottom: var(--margin-bottom, 0);

  font-size: 16px;
  font-weight: $regular;
}
</style>
