import { onMounted, onUnmounted } from 'vue';

/**
 * Sets up an event listener to execute a callback when a specific key is pressed
 * @param {string} key - The key that should trigger the callback
 * @param {Function} callback - The function to execute
 * @returns void
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values} for valid key values
 */
export function useKeyPress(key: string, callback: Function): void {
  /**
   * Event handler; executes the callback if the KeyboardEvent.key matches the desired key
   * @param {KeyboardEvent} event
   * @returns void
   */
  const handleKeyPress = (event: KeyboardEvent): void => {
    if (event.key === key) {
      callback();
    }
  };

  onMounted(() => window.addEventListener('keyup', handleKeyPress));
  onUnmounted(() => window.removeEventListener('keyup', handleKeyPress));
}
