<script setup lang="ts">
import { mdiWindowClose } from '@mdi/js';

const props = withDefaults(
  defineProps<{
    showClose: boolean;
  }>(),
  {
    showClose: true,
  }
);

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const isLoaded = ref<boolean>(false);

onMounted(() => {
  document.body.style.overflow = 'hidden';

  isLoaded.value = true;
});

onUnmounted(() => {
  document.body.style.overflow = '';
});

const close = () => {
  isLoaded.value = false;

  setTimeout(() => {
    emit('close');
  }, 600);
};

useKeyPress('Escape', () => {
  close();
});
</script>

<template>
  <teleport to="body">
    <div v-bind="$attrs" class="hay-modal">
      <Transition name="overlay">
        <div v-if="isLoaded" class="hay-modal__overlay" @click="close"></div>
      </Transition>
      <Transition name="dialog">
        <div v-if="isLoaded" class="hay-modal__dialog">
          <HayButton v-if="showClose" class="hay-modal__close" @click="close">
            <BaseSvgIcon type="mdi" :is="mdiWindowClose" />
          </HayButton>
          <div class="hay-modal__content">
            <slot />
          </div>
        </div>
      </Transition>
    </div>
  </teleport>
</template>

<style scoped lang="scss">
.hay-modal {
  $this: &;
  &__overlay,
  &__dialog {
    position: fixed;
  }
  &__overlay {
    background: color(neutral, 850, 50%);

    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    &.overlay-enter-from,
    &.overlay-leave-to {
      opacity: 0;
    }

    &.overlay-enter-to,
    &.overlay-leave-from {
      opacity: 1;
    }

    &.overlay-enter-active {
      transition: 300ms ease opacity;
    }
    &.overlay-leave-active {
      transition: 300ms ease opacity 300ms;
    }
  }
  &__dialog {
    @include rounded;

    transform: translateX(-50%) translateY(-50%);

    max-height: calc(
      100vh - var(--hay-safe-area-top) - var(--hay-safe-area-bottom)
    );

    overflow: scroll;

    left: 50%;
    top: 50%;
    z-index: 20;
    @media screen and (min-width: 576px) {
      overflow: hidden;
    }
    &.dialog-enter-from,
    &.dialog-leave-to {
      transform: translateX(-50%) translateY(calc(-50% - 32px));

      opacity: 0;

      visibility: hidden;
    }

    &.dialog-enter-to,
    &.dialog-leave-from {
      transform: translateX(-50%) translateY(-50%);

      opacity: 1;

      visibility: visible;
    }

    &.dialog-enter-active {
      transition: 300ms ease opacity 300ms, 300ms ease visibility 300ms,
        300ms ease transform 300ms;
    }
    &.dialog-leave-active {
      transition: 300ms ease opacity 0s, 300ms ease visibility 300ms,
        300ms ease transform 0s;
    }
  }
  &__close {
    color: var(--modal-close-color, color(neutral, 800));

    position: absolute;
    right: var(--modal-close-right, 16px);
    top: var(--modal-close-top, 16px);
    z-index: 1;
  }
}
</style>
