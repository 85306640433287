import { CapacitorUpdater } from '@capgo/capacitor-updater';
import type { BundleInfo } from '@capgo/capacitor-updater';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';

let data: BundleInfo | null = null;

Device.getInfo().then((info) => {
  if (info.platform !== 'web') {
    CapacitorUpdater.notifyAppReady();
    App.addListener('appStateChange', async (state: any) => {
      console.log('appStateChange', state);
      if (state.isActive) {
        console.log('getLatest');
        // Do the download during user active app time to prevent failed download
        const latest = await CapacitorUpdater.getLatest();
        console.log('latest', latest);
        if (latest.url) {
          data = await CapacitorUpdater.download({
            url: latest.url,
            version: latest.version,
          });
          console.log('download', data);
        }
      }
      if (!state.isActive && data) {
        console.log('set');
        // Do the switch when user leave app or when you want
        SplashScreen.show();
        try {
          await CapacitorUpdater.set({ id: data.id });
        } catch (err) {
          console.log(err);
          SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
        }
      }
    });
  }
});

export default defineNuxtPlugin(() => {});
