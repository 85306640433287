import { object, string, ref } from 'yup';

const resetPasswordFields = {
  email: string,
  password: string,
  passwordConfirmation: string,
  token: string,
};

const resetPasswordSchema = object({
  email: string()
    .required('This field is required')
    .email('Please enter a valid email address'),
  password: string()
    .required('This field is required')
    .matches(/[0-9]/, 'The password must contain at least one number.')
    .matches(
      /[a-z]/,
      'The password must contain at least one lowercase letter.'
    )
    .matches(
      /[A-Z]/,
      'The password must contain at least one uppercase letter.'
    )
    .matches(/[^\w]/, 'The password must contain at least one symbol.'),
  passwordConfirmation: string()
    .required('This field is required')
    .oneOf([ref('password')], 'Passwords must match'),
  token: string().required('This field is required'),
});

export { resetPasswordFields, resetPasswordSchema };
