<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query';
import { useField, useForm } from 'vee-validate';

const emit = defineEmits<{
  (event: 'toggleLoginForm'): void;
}>();

const { $mutations } = useNuxtApp();

const router = useRouter();
const route = useRoute();

const { email, token } = route.query;

const { handleSubmit, meta, isSubmitting, resetForm, errors } = useForm({
  validationSchema: resetPasswordSchema,
  initialValues: { email, token },
});

const { value: password } = useField<string>('password');
const { value: passwordConfirmation } = useField<string>(
  'passwordConfirmation'
);

const message = ref();

const {
  mutate: resetPassword,
  data,
  reset,
  isSuccess,
} = useMutation($mutations().user.resetPassword());

const { login } = useSanctum();

const onSubmit = handleSubmit(async (values) => {
  if (isSuccess.value) {
    await login({ email, password });
  } else {
    reset();

    resetPassword(values);
  }
});

watch(data, (newValue) => {
  message.value = newValue;
});

onMounted(() => {
  setTimeout(() => {
    router.replace({ query: {} });
    resetForm();
  }, 10);
});

const isDisabled = computed(() => !meta.value.valid || isSubmitting.value);
</script>

<template>
  <BaseForm @submit.prevent="onSubmit" class="reset-password-form">
    <HayFormRow>
      <HayFormTitle>
        Reset password
        <template #subtitle>your agri marketplace</template>
      </HayFormTitle>
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="email"
        type="email"
        label="Your email"
        name="email"
        placeholder="Your email"
        :model-value="email"
        :error="errors.email"
        readonly="readonly"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="password"
        type="password"
        label="Your password"
        name="password"
        placeholder="Your password"
        v-model="password"
        :error="errors.password"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="password-confirmation"
        type="password"
        label="Confirm your password"
        name="passwordConfirmation"
        placeholder="Confirm your password"
        v-model="passwordConfirmation"
        :error="errors.passwordConfirmation"
      />
    </HayFormRow>
    <HayFormRow class="hidden">
      <HayFormField
        id="token"
        type="hidden"
        label="Confirmation token"
        name="token"
        placeholder="Your token"
        :model-value="token"
        :error="errors.token"
        readonly="readonly"
      />
    </HayFormRow>
    <HayFormRow v-if="message">
      <HayFormMessage>{{ message }}</HayFormMessage>
    </HayFormRow>
    <HayFormRow>
      <HayFormButton
        :disabled="isDisabled"
        v-html="resetPasswordSuccess ? 'Login' : 'Set new password'"
      />
    </HayFormRow>
  </BaseForm>
</template>

<style scoped lang="scss">
.reset-password-form {
}
</style>
