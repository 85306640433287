function capitalizeEachWordInString(string: string) {
  return string.replace(/(^\w|\s\w)/g, (letter) => letter.toUpperCase());
}

function isMobile() {
  if (process.server) {
    return false;
  }

  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
}

export { replaceStringAddNewLines, capitalizeEachWordInString, isMobile };
