<script setup lang="ts">
const error = useError();

const { isApp } = await useDeviceType();

const showFooter = computed(() => !isApp);
const showTabs = computed(() => isApp);
</script>

<template>
  <div class="error">
    <TheHeader class="error__header" />
    <TheApp>
      <NuxtLayout>
        <ErrorMessage v-if="error" class="error__message" :error="error" />
        <PageSection title="Recent Ads" :class="$buildClass('recent')">
          <RecentPosts />
        </PageSection>
      </NuxtLayout>
    </TheApp>
    <TheFooter v-if="showFooter" />
    <TheTabs v-if="showTabs" class="the-app__tabs" />
  </div>
</template>

<style scoped lang="scss">
.error {
  $this: &;

  min-height: 100vh;
  &__header,
  &__footer,
  &__tabs {
    width: 100%;

    left: 0;
    position: fixed;
    right: 0;
    z-index: 9;
  }
  &__header {
    padding-top: var(--hay-safe-area-top);

    top: 0;
  }
  &__footer,
  &__tabs {
    bottom: 0;
  }
  &__message {
    margin: 24px 0 36px;
  }
}
</style>
