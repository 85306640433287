<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    is?: any;
    html?: any;
    width?: string | number;
    height?: string | number;
    viewbox?: string;
    type?: string;
    style?: string;
  }>(),
  {
    width: 24,
    height: 24,
    viewbox: '0 0 24 24',
  }
);

const className = computed(
  () => 'base-svg-icon' + (props.type ? `--${props.type}` : '')
);
</script>

<template>
  <svg
    v-if="html"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    role="presentation"
    :class="className"
    v-html="html"
  />
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    role="presentation"
    :class="className"
  >
    <path v-if="type === 'mdi'" :d="is" />
    <component v-else :is="is" />
  </svg>
</template>

<style scoped lang="scss">
.base-svg-icon {
  $this: &;
  &--mdi {
    @extend #{$this};
    path {
      fill: currentColor;
    }
  }
}
</style>
