<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query';
import { useField, useForm } from 'vee-validate';

const emit = defineEmits<{
  (event: 'toggleLoginForm'): void;
}>();

const { $mutations } = useNuxtApp();

const { logEvent } = useAnalytics();

const { handleSubmit, meta, isSubmitting, resetForm, errors } = useForm<{
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  agreeTerms: boolean;
}>({
  validationSchema: registrationSchema,
});

const { value: name } = useField<string>('name');
const { value: email } = useField<string>('email');
const { value: password } = useField<string>('password');
const { value: passwordConfirmation } = useField<string>(
  'passwordConfirmation'
);
const { value: agreeTerms } = useField<boolean>('agreeTerms');

const { login } = useSanctum();

const router = useRouter();

const { mutate: register } = useMutation({
  ...$mutations().user.register(),
  onSuccess: async (data, variables, context) => {
    alert('Registration successful!');

    shouldRequestAuthentication.value = false;

    await login({ email, password });

    router.push({ name: 'dashboard' });
  },
  onError: async (error) => {
    message.value = error.message;
    console.log(error.message);
  },
});

const clearMessage = () => {
  if (message.value !== '') {
    message.value = '';
  }
};

let message = ref('');

const onSubmit = handleSubmit(async (values) => {
  logEvent('form_submit', { form_name: 'sign_up' });

  await register({ ...values });
});

onMounted(() => {
  setTimeout(() => {
    resetForm();
  }, 10);
});

const isDisabled = computed(() => !meta.value.valid || isSubmitting.value);

const { shouldRequestAuthentication } = storeToRefs(useAuthStore());
</script>

<template>
  <BaseForm @submit.prevent="onSubmit" class="signup-form">
    <HayFormRow>
      <HayFormTitle>
        Sign up
        <template #subtitle>your agri marketplace</template>
      </HayFormTitle>
    </HayFormRow>
    <HayFormRow>
      <HayFormMessage>
        <template #start>Already have an account?</template>
        <template #end>
          <a @click="emit('toggleLoginForm')">Login</a>
        </template>
      </HayFormMessage>
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="name"
        type="text"
        label="Your name"
        name="name"
        placeholder="Your name"
        v-model="name"
        :error="errors.name"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="email"
        type="email"
        label="Your email"
        name="email"
        placeholder="Your email"
        v-model="email"
        :error="errors.email"
        @focus="clearMessage()"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="password"
        type="password"
        label="Create password"
        name="password"
        placeholder="Create password"
        v-model="password"
        :error="errors.password"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="password-confirmation"
        type="password"
        label="Confirm password"
        name="passwordConfirmation"
        placeholder="Confirm password"
        v-model="passwordConfirmation"
        :error="errors.passwordConfirmation"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormMessage>
        <input type="checkbox" v-model="agreeTerms" />
        Agree with our
        <NuxtLink
          :to="{ name: 'terms-and-conditions' }"
          @click="shouldRequestAuthentication = false"
        >
          Terms of use?
        </NuxtLink>
      </HayFormMessage>
    </HayFormRow>
    <HayFormRow>
      <span v-text="message" class="form-message text-red-700"></span>
    </HayFormRow>
    <HayFormRow>
      <HayFormButton :disabled="isDisabled">Sign up</HayFormButton>
    </HayFormRow>
  </BaseForm>
</template>
