import { type Alert } from '@/interfaces/Alert';

const useAlertStore = defineStore('alert', () => {
  const alert: Ref<Alert | undefined> = ref(undefined);
  const queue: Ref<Alert | undefined> = ref(undefined);

  const showAlert = (newAlert: string, immediate?: boolean) => {
    if (immediate) {
      alert.value = newAlert;
    } else {
      queue.value = newAlert;
    }
  };

  const handleQueue = () => {
    if (!queue.value) {
      return;
    }

    alert.value = queue.value;
    queue.value = null;
  };

  const clearAlert = () => {
    alert.value = null;
  };

  return { alert, showAlert, handleQueue, clearAlert };
});

export { useAlertStore };
