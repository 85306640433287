<script setup lang="ts"></script>

<template>
  <button class="hay-form-button">
    <slot />
  </button>
</template>

<style scoped lang="scss">
.hay-form-button {
  letter-spacing: 0.02em;
  transition: 300ms ease color, 300ms ease background;

  background-color: var(--button-background-color, color(primary, 200));
  border: none;
  border-radius: var(--button-border-radius, 8px);
  color: var(--button-color, color(neutral, 850));

  height: 48px;

  font-size: var(--button-font-size, 16px);
  font-weight: var(--button-font-weight, $regular);

  -webkit-appearance: none;
  &:hover {
    cursor: pointer;

    background: color(neutral, 900);
    color: color(neutral, 300);
  }
  &:disabled {
    &:hover {
      background-color: var(--button-background-color, color(primary, 200));
      color: var(--button-color, color(neutral, 850));
    }
    opacity: 0.8;
    cursor: not-allowed;
  }
}
</style>
