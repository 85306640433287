import { useQueryClient } from '@tanstack/vue-query';
import Cookies from 'js-cookie';

const useProfileMutations = () => {
  const { $api } = useNuxtApp();
  const router = useRouter();

  const update = () => ({
    mutationFn: ({ profile }) =>
      $api.profile
        .update({
          profile: toValue(profile),
        })
        .then((resp) => resp.data),
  });

  const destroy = () => ({
    mutationFn: () => $api.user.delete({}).then((resp) => resp.data),
    onSuccess: () => {
      Cookies.remove('x-agriland-auth-token');
      reloadNuxtApp();
    },
    onError: () => {
      console.log('error');
      router.push({ name: 'account-deletion-failed' });
    },
  });

  const blockUser = () => ({
    mutationFn: ({ seller }) =>
      $api.user
        .blockUser({ seller: toValue(seller) })
        .then((resp) => resp.data),
    onSuccess: () => {
      alert('You will no longer receive messages from this user.');
      console.log('success');
    },
    onError: () => {
      console.log('error');
    },
  });

  return { update, destroy, blockUser };
};

export { useProfileMutations };
