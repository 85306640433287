<template>
  <path
    fill="currentColor"
    d="M24 11.966c.022 6.633-5.341 12.033-11.974 12.048C5.389 24.029 0 18.645 0 12.007 0 5.38 5.372 0 12 0c6.611 0 11.977 5.355 12 11.966Z"
  />
  <path
    d="M9.149 15.596 5.524 11.97l-.142-.141-.142.141-1.256 1.256-.141.142.141.141 5.024 5.024.141.142.142-.142L20.056 7.768l.141-.141-.141-.142L18.8 6.229l-.142-.141-.142.141-9.367 9.367Z"
    fill="#FFFFFF"
  />
</template>
