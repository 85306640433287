import { useQueryClient } from '@tanstack/vue-query';

const useNotificationsMutations = () => {
  const { $api } = useNuxtApp();
  const queryClient = useQueryClient();

  const update = () => ({
    mutationFn: ({ notification }) =>
      $api.notifications
        .update({
          notification: toValue(notification),
        })
        .then((resp) => resp.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  return { update };
};

export { useNotificationsMutations };
