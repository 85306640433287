import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { type RouteLocationNormalizedLoaded } from '#vue-router';

import { Device } from '@capacitor/device';

export function useAnalytics() {
  /**
   * Formats a URL, ensuring it starts with 'https://www.agriland.ie/' and ends with a trailing slash.
   *
   * @param url - The input URL to format.
   * @returns The formatted URL.
   * @remarks If the input is not a valid string or an empty string, the function returns undefined.
   */
  const formatUrl = (url: string) => {
    if (typeof url !== 'string' || url.length === 0) {
      return;
    }

    if (!url.includes('https://www.haystack.ie')) {
      url = `https://www.haystack.ie${url}`;
    }

    if (!url.endsWith('/')) {
      url = `${url}/`;
    }

    return url;
  };

  /**
   * Logs a page view event to Firebase Analytics.
   *
   * @param {string} screenName - The name of the screen.
   * @param {string} screenClass - The class of the screen.
   * @param {Object} params - Additional parameters for the page view event (optional).
   * @param {RouteLocationNormalizedLoaded} route - The route override for the page view (optional).
   */
  const logPageView = (
    screenName: string,
    params?: Record<string, any> = {},
    route?: RouteLocationNormalizedLoaded
  ) => {
    const currentRoute = route || useRoute();

    logEvent(
      'page_view',
      {
        screen_name: screenName.replace(/<[^>]*>?/gm, ''),
        screen_class: formatUrl(currentRoute.path),
        page_title: screenName.replace(/<[^>]*>?/gm, ''),
        ...params,
      },
      route
    );
  };

  /**
   * Logs a generic event to Firebase Analytics.
   *
   * @param {string} name - The name of the event.
   * @param {Object} params - Additional parameters for the event (optional).
   * @param {RouteLocationNormalizedLoaded} route - The route override for the event (optional).
   * @returns {void}
   */
  const logEvent = (
    name: string,
    params: Record<string, any> = {},
    route?: RouteLocationNormalizedLoaded
  ) => {
    if (process.server) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    const currentRoute = route || useRoute();

    const { authenticated, currentUser } = storeToRefs(useAuthStore());

    const pageTypes = {
      search: 'search',
      category: 'category',
      'for-sale': 'category',
      'category-for-sale-slug': 'listing',
    };

    Device.getInfo().then((info) => {
      let payload;

      if (info.platform === 'web') {
        window.dataLayer = window.dataLayer || [];
        payload = {
          event: name,
          page_title: currentRoute.name,
          page_location: currentRoute.path,
          is_logged_in: authenticated.value,
          ...(authenticated.value && { user_id: currentUser.value?.uuid }),
          page_id: currentRoute.name,
          page_type: Object.keys(pageTypes).includes(
            currentRoute.name as string
          )
            ? pageTypes[currentRoute.name]
            : 'page',
          platform: info.platform,
          ...params,
        };

        window.dataLayer.push(payload);
      } else {
        payload = {
          name,
          params: {
            page_title: currentRoute.name,
            page_location: currentRoute.path,
            is_logged_in: authenticated.value,
            ...(authenticated.value && { user_id: currentUser.value?.uuid }),
            page_id: currentRoute.name,
            page_type: Object.keys(pageTypes).includes(
              currentRoute.name as string
            )
              ? pageTypes[currentRoute.name]
              : 'page',
            platform: info.platform,
            ...params,
          },
        };

        FirebaseAnalytics.logEvent(payload);
      }

      if (import.meta.env.DEV) {
        console.log('*** Logging GA4 event ***', payload);
      }
    });
  };

  return { logEvent, logPageView };
}
