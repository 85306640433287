import {
  StatusBar,
  BackgroundColorOptions,
  Style,
} from '@capacitor/status-bar';

import { Device } from '@capacitor/device';
import { TextZoom, GetPreferredResult, SetOptions } from '@capacitor/text-zoom';

const setStatusBarColor = async () => {
  console.log('set status bar');
  const options: BackgroundColorOptions = {
    color: '#ffc933',
  };
  await StatusBar.setBackgroundColor(options);
};

const setStatusBarTextColor = async () => {
  await StatusBar.setStyle({ style: Style.Light });
};

Device.getInfo().then((info) => {
  if (info.platform === 'android') {
    setStatusBarColor();
  }

  if (info.platform === 'ios') {
    setStatusBarTextColor();
  }

  if (info.platform !== 'web') {
    TextZoom.getPreferred().then((zoom: GetPreferredResult) => {
      const zoomValue = zoom.value < 1.5 ? zoom.value : 1.5;
      const options: SetOptions = {
        value: zoomValue,
      };
      TextZoom.set(options);
    });
  }
});

export default defineNuxtPlugin(() => {});
