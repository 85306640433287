import { RouteLocationRaw } from 'vue-router';
import { Location } from '@/models/Location';

const capitalize = (string: string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatCategory: (category: Category[]) => {
        if (!category) {
          return;
        }

        const categoryArray = category.map((category) => category.name);

        return categoryArray.join(' > ');
      },
      formatCategoryName: (category) => {
        if (!category) {
          return;
        }

        return category.slice(-1).pop().name;
      },
      formatLocation: (county: County, town: Town) => {
        const location = [];

        if (county?.name) {
          location.push(county.name);
        }

        if (town?.name) {
          location.push(town.name);
        }

        return location.filter((location) => location).join(', ');
      },
      formatPostLink: (category, slug) => {
        if (!(category && slug)) {
          return;
        }

        return {
          name: 'category-for-sale-slug',
          params: {
            category: category.slice(-1).pop().slug,
            slug,
          },
        };
      },
    },
  };
});
