<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: string | number | null;
    name: string;
    id: string;
    options: Record<string, string | number>[];
    valueProperty: string;
    textProperty: string;
  }>(),
  {
    valueProperty: 'name',
    textProperty: 'value',
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', modelValue: string | number | null): void;
}>();

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
</script>

<template>
  <div class="hay-form-field-radio-group">
    <div
      v-for="option in options"
      :key="option[valueProperty]"
      class="hay-form-field-radio-group__option"
    >
      <input
        :id="`${name}-${option[valueProperty]}`"
        v-model="value"
        type="radio"
        :name="name"
        :value="option[valueProperty]"
        class="hay-form-field-radio-group__input"
      />
      <label class="hay-form-field-radio-group__label">
        {{ option[textProperty] }}
      </label>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hay-form-field-radio-group {
  display: flex;
  justify-content: center;
  &__option {
    display: flex;

    align-items: center;
    & + & {
      margin-left: 32px;
    }
  }
  &__input {
    cursor: pointer;
    vertical-align: text-bottom;

    background-color: var(--field-background-color, transparent);
    border: 2px solid
      var(
        --field-border-color,
        var(--field-background-color, color(neutral, 400))
      );
    border-radius: 100%;

    height: 32px;
    margin: 0 8px 0 0;
    width: 32px;

    position: relative;

    -webkit-appearance: none;
    appearance: none;
    &:before {
      content: '';
      transform: scale(0);
      transition: 120ms transform ease-in-out;

      border-radius: 50%;
      box-shadow: inset 32px 32px color(primary, 550);

      height: 28px;
      width: 28px;

      position: absolute;
    }
    &:checked:before {
      transform: scale(1);
    }
  }
}
</style>
