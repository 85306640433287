import { useQueryClient } from '@tanstack/vue-query';

const useFavouritesMutations = () => {
  const { $api, $queries } = useNuxtApp();
  const queryClient = useQueryClient();
  const queryKey = $queries().favourites.all().queryKey;

  const add = () => ({
    mutationFn: ({ uuid }) =>
      $api.favourites.add({ uuid: toValue(uuid) }).then((resp) => resp.data),
    onMutate: async (newValue) => {
      await queryClient.cancelQueries({ queryKey });

      const favourites = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (oldValue) => [...oldValue, newValue]);

      return { favourites };
    },

    onError: (error, newValue, context) => {
      queryClient.setQueryData(queryKey, context.favourites);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const remove = () => ({
    mutationFn: ({ uuid }) =>
      $api.favourites.remove({ uuid: toValue(uuid) }).then((resp) => resp.data),
    onMutate: async (newValue) => {
      await queryClient.cancelQueries({ queryKey });

      const favourites = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (oldValue) =>
        oldValue.filter((favourite) => favourite.uuid !== newValue.uuid.value)
      );

      return { favourites };
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData(queryKey, context.favourites);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { add, remove };
};

export { useFavouritesMutations };
