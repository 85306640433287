import HttpFactory from '@/repository/factory';

class FieldsModule extends HttpFactory {
  private ENDPOINT = useEnv('CF_SEARCH_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'fields';
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async getBySlug({ slug = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${slug}`;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }
}

export { FieldsModule };
