import { createQueryKeys } from '@lukemorales/query-key-factory';

const useProfileQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('profile', {
    get: (params = {} as Ref, returns = 'user') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.profile
          .get({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useProfileQueries };
