<script setup lang="ts">
const props = defineProps<{
  modelValue: string | number | string[] | undefined;
  name: string;
  id: string;
  placeholder?: string;
}>();

const emit = defineEmits<{
  (
    event: 'update:modelValue',
    modelValue: string | number | string[] | undefined
  ): void;
}>();

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
</script>

<template>
  <textarea
    :id="id"
    v-model="value"
    :name="name"
    :placeholder="placeholder"
    class="hay-form-field-textarea"
  ></textarea>
</template>

<style scoped lang="scss">
.hay-form-field-textarea {
  resize: none;
  transition: 300ms ease color;

  background: none;
  border: none;
  color: var(--field-text-color, color(neutral, 400));
  outline: none !important;

  height: 120px;
  padding: 0 var(--field-horizontal-padding, 16px);
  padding-bottom: 16px;
  padding-top: 16px;
  width: 100%;

  position: relative;
  z-index: 1;

  font-size: var(--field-font-size, 14px);
  font-weight: var(--field-font-weight, 400);
  &:focus {
    color: var(--field-text-color, color(neutral, 100));
  }
}
</style>
