<script setup lang="ts">
import {
  mdiMagnify,
  mdiChatOutline,
  mdiAccountCircleOutline,
  mdiAccountCheck,
} from '@mdi/js';

import AddCircle from '@/icons/ui/AddCircle.vue';

const { currentUser } = storeToRefs(useAuthStore());
</script>

<template>
  <nav class="the-tabs">
    <TabButton :to="{ name: 'index' }" :icon="mdiMagnify">Search</TabButton>
    <TabButton :to="{ name: 'dashboard-my-messages' }" :icon="mdiChatOutline">
      Messages
    </TabButton>
    <TabButton :to="{ name: 'dashboard-my-adverts-new' }">
      <template #icon>
        <BaseSvgIcon :is="AddCircle" />
      </template>
      Place ad
    </TabButton>

    <TabButton
      :icon="currentUser ? mdiAccountCheck : mdiAccountCircleOutline"
      :to="{ name: 'dashboard' }"
    >
      <template v-if="!currentUser">Login</template>
      <template v-else>My Haystack</template>
    </TabButton>
  </nav>
</template>

<style scoped lang="scss">
.the-tabs {
  $this: &;

  box-shadow: 0 -2px 2px rgba(45, 57, 53, 0.1);

  background: color(neutral, 100);

  height: calc((var(--hay-safe-area-bottom) / 2) + 72px);
  margin: 0 auto;
  max-width: 100%;

  display: grid;

  list-style-type: none;

  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
</style>
