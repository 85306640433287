<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    layout?: string;
    posts: (Post | undefined)[];
    isLoading: boolean;
    perPage: number;
    perRow: number;
    showSpotlightBadge?: boolean;
    showBusinessCard?: boolean;
    showGalleryCount?: boolean;
    showFavourite?: boolean;
    showFavouritePosition?: 'image' | 'body';
    showMeta?: boolean;
  }>(),
  {
    layout: 'grid',
    perPage: 21,
    perRow: 3,
    showSpotlightBadge: true,
    showBusinessCard: true,
    showGalleryCount: true,
    showFavourite: true,
    showFavouritePosition: 'image',
    showMeta: true,
  }
);
</script>

<template>
  <ul :class="`post-list--${layout}`" :layout="layout">
    <li
      v-for="(post, index) in props.isLoading ? props.perPage : props.posts"
      class="post-list__item"
    >
      <PostCard
        v-bind="post"
        :key="index"
        :is-loading="isLoading"
        :layout="layout === 'list' ? 'row' : 'card'"
        :show-spotlight-badge="showSpotlightBadge"
        :show-business-card="showBusinessCard"
        :show-gallery-count="showGalleryCount"
        :show-favourite="showFavourite"
        :show-favourite-position="showFavouritePosition"
        :show-meta="showMeta"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.post-list {
  $this: &;

  margin: 0;
  padding-left: 0;
  padding-right: 0;

  list-style-type: none;
  &--list,
  &--grid {
    @extend #{$this};
  }
  &--list {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: column;

      row-gap: 0;
      column-gap: 0;
    }

    display: grid;

    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    & > #{$this}__item + #{$this}__item {
      @include media-breakpoint-up(sm) {
        margin-top: 16px;
      }
    }
  }
  &--grid {
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(v-bind(perRow), minmax(0, 1fr));
    }

    display: grid;

    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  &__item {
    display: flex;
  }
}
</style>
