<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    form?: string;
  }>(),
  {
    form: 'login',
  }
);

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const form = ref(props.form);

const displayForm = (type: string) => {
  form.value = type;
};
</script>

<template>
  <HayModal @close="emit('close')">
    <div class="authentication-form">
      <LoginForm
        v-if="form === 'login'"
        @toggle-signup-form="displayForm('signup')"
        @toggle-forgot-password-form="displayForm('forgot-password')"
      />
      <ForgotPasswordForm
        v-if="form === 'forgot-password'"
        @toggle-signup-form="displayForm('signup')"
        @toggle-login-form="displayForm('login')"
        @close="emit('close')"
      />
      <ResetPasswordForm
        v-if="form === 'reset-password'"
        @toggle-login-form="displayForm('login')"
      />
      <SignupForm
        v-if="form === 'signup'"
        @toggle-login-form="displayForm('login')"
      />
    </div>
  </HayModal>
</template>

<style scoped lang="scss">
.authentication-form {
  background: color(primary, 550);

  max-width: 480px;
  padding: 24px;
  width: calc(100vw - 48px);

  --row-spacing: 16px;

  --field-background-color: #{color(primary, 400)};
  --field-text-color: #{color(neutral, 800)};
  --field-active-background-color: #{color(primary, 300)};
}
</style>
