<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query';
import { useField, useForm } from 'vee-validate';

const emit = defineEmits<{
  (event: 'toggleSignupForm'): void;
  (event: 'toggleLoginForm'): void;
  (event: 'close'): void;
}>();

const { $mutations } = useNuxtApp();

const { logEvent } = useAnalytics();

const { handleSubmit, meta, isSubmitting, resetForm, errors } = useForm<
  typeof forgotPasswordFields
>({
  validationSchema: forgotPasswordSchema,
});

const { value: email } = useField<string>('email');

const message = ref();

const {
  mutate: forgotPassword,
  data,
  reset,
  isSuccess,
} = useMutation($mutations().user.forgotPassword());

const onSubmit = handleSubmit(async (values) => {
  logEvent('form_submit', { form_name: 'password_reset' });

  if (isSuccess.value) {
    emit('close');
  } else {
    reset();
    forgotPassword({ email });
  }
});

watch(data, (newValue) => {
  message.value = newValue;
});

onMounted(() => {
  setTimeout(() => {
    resetForm();
  }, 10);
});

const isDisabled = computed(() => !meta.value.valid || isSubmitting.value);
</script>

<template>
  <BaseForm @submit.prevent="onSubmit" class="forgot-password-form">
    <HayFormRow>
      <HayFormTitle>
        Forgot password
        <template #subtitle>your agri marketplace</template>
      </HayFormTitle>
    </HayFormRow>
    <HayFormRow>
      <HayFormMessage>
        <template #start>Dont have an account?</template>
        <template #end>
          <a @click="emit('toggleSignupForm')">Sign up</a>
        </template>
      </HayFormMessage>
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="email"
        type="email"
        label="Your email"
        name="email"
        placeholder="Your email"
        v-model="email"
        :error="errors.email"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormMessage v-if="message">{{ message }}</HayFormMessage>
      <HayFormMessage v-else>
        <a @click="emit('toggleLoginForm')">Back to login?</a>
      </HayFormMessage>
    </HayFormRow>
    <HayFormRow>
      <HayFormButton
        :disabled="isDisabled"
        v-html="forgotPasswordSuccess ? 'Okay' : 'Send password reset link'"
      />
    </HayFormRow>
  </BaseForm>
</template>

<style scoped lang="scss">
.forgot-password-form {
}
</style>
