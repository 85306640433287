<template>
  <g
    stroke="#fff"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    transform="translate(4.036 3.478)"
  >
    <rect width="16" height="16" rx="3" style="fill: none" />
    <path
      d="M11 4h.01M0 11l4-4c.929-.893 2.072-.893 3 0l5 5m-2-2 1-1c.929-.893 2.072-.893 3 0l2 2"
      style="fill: none"
    />
  </g>
</template>
