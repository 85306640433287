export default defineNuxtPlugin(() => {
  return {
    provide: {
      insertNewLines: (haystack: string, needle?: string) =>
        haystack.replace(
          new RegExp(needle || /(?:\r\n|\r|\n|\\n)/, 'g'),
          '<br />'
        ),
    },
  };
});
