import HttpFactory from '@/repository/factory';

import { type County } from '@/interfaces/County';

class CountiesModule extends HttpFactory {
  private ENDPOINT = useEnv('CF_SEARCH_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'counties';
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async getAll({ params = {}, options: opts = {} } = {}) {
    const url = this.BASEURL;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }

  async getBySlug({ slug = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${slug}`;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }
}

export { CountiesModule };
