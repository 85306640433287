import { useQueryClient } from '@tanstack/vue-query';

const useUserMutations = () => {
  const { $api } = useNuxtApp();

  const login = () => ({
    mutationFn: ({ name, email, password, passwordConfirmation }) =>
      $api.user
        .login({
          email: toValue(email),
          password: toValue(password),
        })
        .then((resp) => resp.data.token),
  });

  const register = () => ({
    mutationFn: ({ name, email, password, passwordConfirmation }) =>
      $api.user
        .register({
          name: toValue(name),
          email: toValue(email),
          password: toValue(password),
          passwordConfirmation: toValue(passwordConfirmation),
        })
        .then((resp) => resp.data),
  });

  const forgotPassword = () => ({
    mutationFn: ({ email }) =>
      $api.user
        .forgotPassword({ email: toValue(email) })
        .then((resp) => resp.data.message),
  });

  const resetPassword = () => ({
    mutationFn: ({ email, password, passwordConfirmation, token }) =>
      $api.user
        .resetPassword({
          email: toValue(email),
          password: toValue(password),
          passwordConfirmation: toValue(passwordConfirmation),
          token: toValue(token),
        })
        .then((resp) => resp.data.message),
  });

  const sendCode = () => ({
    mutationFn: ({ prefix, number }) =>
      $api.user
        .sendCode({
          prefix: toValue(prefix),
          number: toValue(number),
        })
        .then((resp) => resp.data),
  });

  const verifyPhone = () => ({
    mutationFn: ({ code, phone }) =>
      $api.user
        .verifyPhone({
          code: toValue(code),
          phone: toValue(phone),
        })
        .then((resp) => resp.data),
  });

  return {
    login,
    register,
    forgotPassword,
    resetPassword,
    sendCode,
    verifyPhone,
  };
};

export { useUserMutations };
