import HttpFactory from '@/repository/factory';

class MyPostsModule extends HttpFactory {
  private ENDPOINT = useEnv('APP_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'user/adverts';
  private AUTHENTICATED = true;
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async getAll({ params = {}, options: opts = {} } = {}) {
    const url = this.BASEURL;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async getByUuid({ uuid = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${uuid}`;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async create({ post, params = {}, options: opts = {} }) {
    const url = this.BASEURL;
    const method = 'POST';
    const data = post;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async update({ post, params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${post.uuid}`;
    const method = 'POST';
    const data = { ...post, _method: 'patch' };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async publish({ uuid = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${uuid}/publish`;
    const method = 'PATCH';
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, options, authenticated });
  }

  async sold({ uuid = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${uuid}/sold`;
    const method = 'PATCH';
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, options, authenticated });
  }

  async withdraw({ uuid = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${uuid}/withdraw`;
    const method = 'PATCH';
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, options, authenticated });
  }

  async relist({ uuid = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${uuid}/relist`;
    const method = 'PATCH';
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, options, authenticated });
  }
}

export { MyPostsModule };
