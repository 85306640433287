const currencyIELocale = Intl.NumberFormat('en-IE');

export default defineNuxtPlugin(() => {
  return {
    provide: {
      twoDecimalPlaces: (value: number) =>
        value &&
        new Intl.NumberFormat('en-IE', { minimumFractionDigits: 2 }).format(
          value
        ),
      formatPriceWithCurrency: (
        value: number,
        currency: string,
        priceOnApplication?: boolean
      ): string => {
        const price = [];

        price.push(currency === 'GBP' ? '£' : '€');
        price.push(priceOnApplication ? 'POA' : currencyIELocale.format(value));

        return price.join('');
      },
      dividePrice: (price: number, divisor: number) => {
        return divisor !== 0 ? price / divisor : 0;
      },
    },
  };
});
