<script setup lang="ts">
import Image from '@/icons/ui/Image.vue';

const props = defineProps<{
  current?: number;
  total: number;
}>();
</script>

<template>
  <div class="gallery-count">
    <BaseSvgIcon :is="Image" />
    <template v-if="props.current">
      <span>{{ props.current }}</span>
      /
    </template>
    <span>{{ props.total }}</span>
  </div>
</template>

<style scoped lang="scss">
.gallery-count {
  color: color(neutral, 100);

  padding: 0 8px;

  display: flex;

  font-size: 14px;
  font-weight: 600;

  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 2px;
  }
  span {
    text-align: center;

    min-width: 10px;
  }
}
</style>
