import { useQuery, useMutation } from '@tanstack/vue-query';

const useFavourites = ({ uuid, type, category, county }) => {
  const { $queries, $mutations } = useNuxtApp();

  const { authenticated } = storeToRefs(useAuthStore());
  const { logEvent } = useAnalytics();

  const { data, isSuccess } = useQuery({
    ...$queries().favourites.all(),
    enabled: !!authenticated.value,
  });

  const { mutate: add } = useMutation({
    ...$mutations().favourites.add(),
    onSuccess() {
      logEvent('added_to_favourites', {
        listing_id: toValue(uuid),
        listing_type: toValue(type),
        listing_category: toValue(category).at(-1).slug,
        listing_county: toValue(county).slug,
      });
    },
  });

  const { mutate: remove } = useMutation({
    ...$mutations().favourites.remove(),
    onSuccess() {
      logEvent('removed_from_favourites', {
        listing_id: toValue(uuid),
        listing_type: toValue(type),
        listing_category: toValue(category).at(-1).slug,
        listing_county: toValue(county).slug,
      });
    },
  });

  const isAFavourite = computed(
    () => !!data.value?.find((post) => post.uuid === toValue(uuid))
  );

  const toggleFavourite = () =>
    isAFavourite.value ? remove({ uuid }) : add({ uuid });

  return { isAFavourite, toggleFavourite };
};

export { useFavourites };
