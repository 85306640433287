import { createQueryKeys } from '@lukemorales/query-key-factory';

const useUserQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('user', {
    get: (params = {} as Ref, returns = 'user') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.user
          .authenticate({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useUserQueries };
