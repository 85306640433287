import { createQueryKeys } from '@lukemorales/query-key-factory';

const usePaymentLinksQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('paymentLinks', {
    get: (params = {} as Ref, returns = 'paymentLink') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.paymentLinks
          .get({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { usePaymentLinksQueries };
