import HttpFactory from '@/repository/factory';

class ThreadsModule extends HttpFactory {
  private ENDPOINT = useEnv('APP_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'threads';
  private AUTHENTICATED = true;
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async getAll({ params = {}, options: opts = {} } = {}) {
    const url = `${this.ENDPOINT}/api/v${this.VERSION}/user/threads`;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async getByUuid({ uuid = '', params = {}, options: opts = {} }) {
    const url = `${this.ENDPOINT}/api/v${this.VERSION}/user/threads/${uuid}`;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async create({ uuid = '', content = '', params = {}, options: opts = {} }) {
    const url = this.BASEURL;
    const method = 'POST';
    const data = { advert: uuid, content };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async reply({ uuid = '', content = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${uuid}/messages/`;
    const method = 'POST';
    const data = { content };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }
}

export { ThreadsModule };
