<script setup lang="ts">
import { useField } from 'vee-validate';

import HayFormFieldInput from '@/components/ui/HayForm/HayFormField/HayFormFieldInput.vue';
import HayFormFieldSelect from '@/components/ui/HayForm/HayFormField/HayFormFieldSelect.vue';
import HayFormFieldTextarea from '@/components/ui/HayForm/HayFormField/HayFormFieldTextarea.vue';
import HayFormFieldRadioGroup from '@/components/ui/HayForm/HayFormField/HayFormFieldRadioGroup.vue';

const slots = useSlots();

const props = defineProps<{
  modelValue?: string | string[] | number | null;
  type: string;
  label: string;
  name: string;
  id?: string;
  options?: Record<string, string | number>[];
  dropzoneOptions?: Record<string, string>;
  valueProperty?: string;
  textProperty?: string;
  placeholder?: string;
  showLabel?: boolean;
  error?: string;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | string[] | number | null): void;
}>();

const kebabType = computed(() =>
  props.type.replace(/[A-Z]/g, '-$&').toLowerCase()
);

const fieldComponent = computed(() => {
  return {
    text: HayFormFieldInput,
    number: HayFormFieldInput,
    email: HayFormFieldInput,
    password: HayFormFieldInput,
    select: HayFormFieldSelect,
    textarea: HayFormFieldTextarea,
    radioGroup: HayFormFieldRadioGroup,
  }[props.type];
});

const { value } = useField<string | string[] | number | null>(
  toRef(props, 'name')
);

value.value = props.modelValue;
</script>

<template>
  <div :class="`hay-form-field--${kebabType}`">
    <label
      class="hay-form-field__label"
      :for="name"
      :class="{ 'hay-form-field__label-hidden': !showLabel }"
    >
      {{ label }}
    </label>
    <div class="hay-form-field__input">
      <div v-if="slots.start" class="hay-form-field__slot--start">
        <slot name="start" />
      </div>
      <div class="hay-form-field__slot--default">
        <Component
          v-bind="$attrs"
          :is="fieldComponent"
          :id="id"
          :model-value="value"
          :type="type"
          :name="name"
          :options="options"
          :placeholder="placeholder"
          :value-property="valueProperty"
          :text-property="textProperty"
          @update:model-value="(value: string) => emit('update:modelValue', value)"
        >
          <template v-if="slots.icon" #icon><slot name="icon" /></template>
        </Component>
      </div>
      <div
        v-if="slots.end || type === 'select'"
        class="hay-form-field__slot--end"
      >
        <slot name="end" />
      </div>
    </div>
    <div v-if="props.error" class="hay-form-field__error">
      {{ props.error }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.hay-form-field {
  $this: &;

  overflow: hidden;

  flex: var(--field-width, 1);
  &__label {
    letter-spacing: 0.02;

    color: color(neutral, 100);

    margin-bottom: 8px;

    display: block;

    &-hidden {
      @include visually-hidden;
    }
  }
  &__input {
    color: var(--field-text-color, color(neutral, 400));

    height: var(--field-height, auto);

    display: flex;

    position: relative;

    justify-content: space-between;
    align-items: center;
  }
  &__slot {
    &--start,
    &--end {
      @extend #{$this}__slot;
    }
    &--start {
      padding-left: 2px;
    }
    &--default {
      transition: 300ms ease border-color, 300ms ease background-color;

      background-color: var(--field-background-color, color(neutral, 850));
      border-color: var(
        --field-border-color,
        var(--field-background-color, color(neutral, 400))
      );
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;

      overflow: hidden;

      flex: 1;
      &:focus-within {
        background-color: var(
          --field-active-background-color,
          color(neutral, 800)
        );
        border-color: var(
          --field-active-border-color,
          var(--field-background-color, color(neutral, 100))
        );
      }

      #{$this}--file > .hay-form-field__input > & {
        @include rounded;

        background: none;
        border-style: dashed;
        border-width: 1.5px;
      }
      #{$this}--radio-group > .hay-form-field__input > & {
        background: none;
        border: 0;
      }

      #{$this}--categoriser > .hay-form-field__input > & {
        background: none;
        border: none;
      }
    }
    &--end {
      padding-right: 2px;
    }
  }
  &__error {
    color: #cc0000;

    margin: 5px 0 0 5px;

    font-size: 12px;
  }
}
</style>
