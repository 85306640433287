const useClicksMutations = () => {
  const { $api } = useNuxtApp();

  const register = () => ({
    mutationFn: ({ uuid }) =>
      $api.clicks.register({ uuid: toValue(uuid) }).then((resp) => resp.data),
  });

  return { register };
};

export { useClicksMutations };
