<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';

const { $queries } = useNuxtApp();

const queryClient = useQueryClient();
const { logEvent } = useAnalytics();

queryClient.prefetchQuery({
  ...$queries().categories.all(),
  staleTime: 24 * 60 * 60 * 1000,
});

queryClient.prefetchQuery({
  ...$queries().counties.all(),
  staleTime: 24 * 60 * 60 * 1000,
});

const { getPreferences } = usePreferenceStore();
getPreferences();

const { shouldRequestAuthentication, authenticationForm } = storeToRefs(
  useAuthStore()
);

const hideAuthenticationForm = () => {
  shouldRequestAuthentication.value = false;
};

const { alert } = storeToRefs(useAlertStore());

const isLoading = ref(true);

const onUpdated = () => {
  isLoading.value = false;
};

logEvent('app_version', { version: useEnv('APP_VERSION') });

const { isApp } = await useDeviceType();

const showFooter = computed(() => !isApp);
const showTabs = computed(() => isApp);
</script>

<template>
  <div class="app">
    <TheHeader class="app__header" />
    <TheApp>
      <NuxtPage />
    </TheApp>
    <TheFooter v-if="showFooter" />
    <TheTabs v-if="showTabs" class="app__tabs" />
    <HayAlert v-if="alert" />
    <AuthenticationForm
      v-if="shouldRequestAuthentication"
      :form="authenticationForm"
      @close="hideAuthenticationForm"
    />
    <ConfirmDialog />
  </div>
</template>

<style scoped lang="scss">
.app {
  $this: &;

  min-height: 100vh;
  &__header,
  &__footer,
  &__tabs {
    width: 100%;

    left: 0;
    position: fixed;
    right: 0;
    z-index: 9;
  }
  &__header {
    padding-top: var(--hay-safe-area-top);

    top: 0;
  }
  &__footer,
  &__tabs {
    bottom: 0;
  }
  &--loading {
    @extend .app;
    #{$this}__content {
      min-height: 100vh;
    }
  }
}
</style>
