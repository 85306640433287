import { CapacitorHttp } from '@capacitor/core';
import Cookies from 'js-cookie';

class HttpFactory {
  private requestInterceptors = [];
  private responseInterceptors = [];

  constructor(interceptors) {
    const { request, response } = interceptors;

    this.requestInterceptors = request;
    this.responseInterceptors = response;
  }

  private sanitizeParams(params) {
    return Object.keys(params).reduce((acc, curr) => {
      if (params[curr] !== null) {
        if (typeof params[curr] === 'number') {
          acc[curr] = String(params[curr]);
        } else {
          acc[curr] = params[curr];
        }
      }

      return acc;
    }, {});
  }

  async call({
    url = '',
    method = 'GET',
    data = {},
    options = {},
    authenticated = false,
  }) {
    const headers = {
      accept: 'application/json',
      ...options.headers,
    };

    headers['x-agriland-session-id'] = await useSessionId();

    if (authenticated) {
      const xsrf = await Cookies.get('XSRF-TOKEN');

      if (xsrf) {
        headers['x-xsrf-token'] = xsrf;
      }

      headers['cache-control'] = 'no-cache';

      if (method === 'POST') {
        headers['content-type'] = 'application/json';
      }

      options['webFetchExtra'] = options['webFetchExtra'] || {};
      options['webFetchExtra']['credentials'] = 'include';

      const token = await Cookies.get('x-agriland-auth-token');

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
    }

    options.params = this.sanitizeParams(options.params);

    let config = {
      url,
      method,
      headers,
      ...(Object.keys(data).length && { data }),
      ...options,
    };

    config = this.requestInterceptors
      .map((interceptor) => interceptor.success)
      .reduce((acc, interceptor) => interceptor(acc), config);

    let response = await CapacitorHttp.request(config);

    if (response.status >= 300) {
      throw response.data;
    }

    if (this.responseInterceptors.length) {
      response = this.responseInterceptors
        .map((interceptor) => interceptor.success)
        .reduce((acc, interceptor) => interceptor(acc), response);
    }

    return response;
  }
}

export default HttpFactory;
