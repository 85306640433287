<script setup lang="ts">
import { useField, useForm } from 'vee-validate';

const emit = defineEmits<{
  (event: 'toggleSignupForm'): void;
  (event: 'toggleForgotPasswordForm'): void;
}>();

const router = useRouter();

const { logEvent } = useAnalytics();

const clearMessage = () => {
  if (message.value !== '') {
    message.value = '';
  }
};

let message = ref('');

const { shouldRequestAuthentication, nextRoute } = storeToRefs(useAuthStore());

const { handleSubmit, meta, isSubmitting, resetForm, errors } = useForm<
  typeof loginFields
>({
  validationSchema: loginSchema,
});

const { value: email } = useField<string>('email');
const { value: password } = useField<string>('password');

const { login } = useSanctum();

const onSubmit = handleSubmit(async (values) => {
  logEvent('form_submit', { form_name: 'login' });

  await login(values)
    .then((data) => {
      shouldRequestAuthentication.value = false;

      if (nextRoute.value) {
        router.push(nextRoute.value);
        nextRoute.value = undefined;
      }
    })
    .catch((error) => (message.value = error.message));
});

onMounted(() => {
  setTimeout(() => {
    resetForm();
  }, 20);
});

const isDisabled = computed(() => !meta.value.valid || isSubmitting.value);

const onForgotPassword = () => {
  logEvent('click', { action: 'forgot_password' });

  emit('toggleForgotPasswordForm');
};
</script>

<template>
  <BaseForm @submit.prevent="onSubmit" class="login-form">
    <HayFormRow>
      <HayFormTitle>
        Login
        <template #subtitle>your agri marketplace</template>
      </HayFormTitle>
    </HayFormRow>
    <HayFormRow>
      <HayFormMessage>
        <template #start>Dont have an account?</template>
        <template #end>
          <a @click="emit('toggleSignupForm')">Sign up</a>
        </template>
      </HayFormMessage>
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="email"
        type="email"
        label="Your email"
        name="email"
        placeholder="Your email"
        v-model="email"
        :error="errors.email"
        @focus="clearMessage()"
      />
    </HayFormRow>
    <HayFormRow>
      <HayFormField
        id="password"
        type="password"
        label="Your password"
        name="password"
        placeholder="Your password"
        v-model="password"
        :error="errors.password"
        @focus="clearMessage()"
      />
    </HayFormRow>
    <HayFormRow>
      <span v-text="message" class="form-message text-red-700"></span>
    </HayFormRow>
    <HayFormRow>
      <HayFormMessage>
        <a @click="onForgotPassword">Forgot password?</a>
      </HayFormMessage>
    </HayFormRow>
    <HayFormRow>
      <HayFormButton :disabled="isDisabled">Login</HayFormButton>
    </HayFormRow>
  </BaseForm>
</template>
