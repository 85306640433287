<script setup lang="ts">
import {
  mdiAccountCircle,
  mdiAccountCircleOutline,
  mdiFormSelect,
  mdiBullhornOutline,
  mdiChatOutline,
  mdiBookmarkOutline,
  mdiCreditCardOutline,
  mdiClipboardTextSearchOutline,
  mdiLogin,
  mdiLogout,
} from '@mdi/js';

import Search from '@/icons/ui/Search.vue';
import AddCircle from '@/icons/ui/AddCircle.vue';

const router = useRouter();

const menuToggled = ref<boolean>(false);

const { logEvent } = useAnalytics();

const onToggleMenu = () => {
  menuToggled.value = !menuToggled.value;

  if (menuToggled.value) {
    logEvent('click', { action: 'navigation_opened' });
  }
};

let closeMenu: NodeJS.Timeout;

watch(menuToggled, (newValue) => {
  if (newValue) {
    closeMenu = setTimeout(() => {
      menuToggled.value = false;
    }, 10000000);
  } else {
    clearTimeout(closeMenu);
  }
});

const {
  authenticated,
  currentUser,
  shouldRequestAuthentication,
  authenticationForm,
} = storeToRefs(useAuthStore());

const { logout } = useSanctum();

const shouldShowSearch = ref(false);

const onToggleSearch = () => {
  shouldShowSearch.value = !shouldShowSearch.value;
};

const primaryNavItems = computed(() => [
  {
    name: 'My Profile',
    slug: '/dashboard/my-profile',
    icon: mdiAccountCircleOutline,
  },
  {
    name: 'My Adverts',
    slug: '/dashboard/my-adverts',
    icon: mdiBullhornOutline,
  },
  {
    name: 'My Messages',
    slug: '/dashboard/my-messages',
    icon: mdiChatOutline,
  },
  {
    name: 'My Favourites',
    slug: '/dashboard/my-favourites',
    icon: mdiBookmarkOutline,
  },
  {
    name: 'Payment history',
    slug: '/dashboard/payment-history',
    icon: mdiCreditCardOutline,
  },
  ...(!authenticated.value
    ? [
        {
          name: 'Log in / Sign up',
          slug: '/dashboard',
          icon: mdiLogin,
        },
      ]
    : []),
  ...(authenticated.value
    ? [
        {
          name: 'Log out',
          action: logout,
          icon: mdiLogout,
        },
      ]
    : []),
]);

const onPlaceAd = () => {
  if (authenticated.value) {
    logEvent('place_ad', { action: 'step_1' });
  } else {
    logEvent('place_ad', { action: 'login' });
  }

  router.push({ name: 'dashboard-my-adverts-new' });
};

const onLogin = () => {
  logEvent('click', { action: 'login' });

  authenticationForm.value = 'login';
  shouldRequestAuthentication.value = true;
};

const onSignup = () => {
  logEvent('click', { action: 'sign_up' });

  authenticationForm.value = 'signup';
  shouldRequestAuthentication.value = true;
};

const { isApp } = await useDeviceType();

const logoSlot = computed(() => (isApp ? 'default' : 'start'));
</script>

<template>
  <header class="the-header">
    <TheNavbar class="the-header__navbar">
      <template #[logoSlot]>
        <NuxtLink
          :to="{ name: 'index', params: null }"
          class="the-header__logo"
        >
          <img src="/img/logo.svg" alt="Haystack" />
        </NuxtLink>
      </template>
      <template v-if="!isApp" #end>
        <ButtonGroup class="the-header__buttons">
          <HayButton class="the-header__button--search" @click="onToggleSearch">
            <template #iconStart>
              <BaseSvgIcon :is="Search" />
            </template>
            Search
            <teleport to="body">
              <SearchBox v-if="shouldShowSearch" @close="onToggleSearch" />
            </teleport>
          </HayButton>
          <HayButton class="the-header__button--place-ad" @click="onPlaceAd">
            <template #iconStart>
              <BaseSvgIcon :is="AddCircle" />
            </template>
            Place ad
          </HayButton>
          <ClientOnly>
            <HayButton
              v-if="!authenticated"
              class="the-header__button--login"
              @click="onLogin"
            >
              Log In
            </HayButton>
            <HayButton
              v-if="!authenticated"
              class="the-header__button--sign-up"
              @click="onSignup"
            >
              Sign Up
            </HayButton>
            <HayButton
              :to="{ name: 'dashboard' }"
              class=""
              :class="
                authenticated
                  ? 'the-header__button--my-account-logged-in'
                  : 'the-header__button--my-account'
              "
            >
              <template #iconStart>
                <BaseSvgIcon
                  :is="
                    authenticated ? mdiAccountCircle : mdiAccountCircleOutline
                  "
                  type="mdi"
                />
              </template>
              My Haystack
            </HayButton>
          </ClientOnly>
          <HayButton class="the-header__button--menu" @click="onToggleMenu">
            <AnimatedMenuIcon :menu-toggled="menuToggled" />
          </HayButton>
        </ButtonGroup>
      </template>
    </TheNavbar>
    <ClientOnly>
      <teleport to="body">
        <TheMenu
          :menu-toggled="menuToggled"
          :menu-items="primaryNavItems"
          @toggle-menu="onToggleMenu"
        />
      </teleport>
    </ClientOnly>
  </header>
</template>

<style scoped lang="scss">
.the-header {
  $this: &;

  @include media-breakpoint-up(md) {
    --button-height: 52px;
  }

  background-color: color(primary, 550);
  box-shadow: 0 2px 2px rgba(45, 57, 53, 0.1);

  --button-height: 40px;
  &__logo {
    @include media-breakpoint-up(md) {
      width: 142px;
    }

    width: 114px;

    @media (max-width: 374px) {
      width: 90px;
    }

    display: block;
    img {
      height: auto;
      width: 100%;

      display: block;
    }
  }
  &__buttons {
    @include media-breakpoint-up(md) {
      --button-left-margin: 16px;
    }

    --button-left-margin: 8px;
    &--logged-in #{$this}__button--my-account {
      display: flex;
    }
  }
  &__button {
    @include media-breakpoint-up(md) {
      --button-padding: 0 16px;
    }

    --button-padding: 0 8px;
    --button-hover-background-color: #{color(primary, 350)};
    --button-border-radius: 8px;
    &--search,
    &--place-ad,
    &--login,
    &--sign-up,
    &--my-account,
    &--menu {
      @extend #{$this}__button;
    }
    &--search,
    &--my-account {
      display: none;
      @media only screen and (min-width: 341px) {
        display: flex;
      }
      &:deep(.hay-button__slot--icon-start) {
        margin-right: 0;
      }
      &:deep(.hay-button__slot--default) {
        display: none;
      }
    }
    &--my-account {
      @media only screen and (min-width: 768px) {
        display: none;
      }
    }
    &--my-account-logged-in {
      @extend #{$this}__button--my-account;

      display: flex;
    }
    &--search {
      &:deep(.hay-button__slot--icon-start) {
        @include media-breakpoint-up(sm) {
          margin-right: 8px;
        }
      }
      &:deep(.hay-button__slot--default) {
        @include media-breakpoint-up(sm) {
          display: block;
        }
      }
    }
    &--login,
    &--sign-up {
      @include media-breakpoint-up(md) {
        display: flex;
      }

      display: none;
    }
    &--my-account {
      align-items: center;

      &:deep(.hay-button__slot--icon-start) {
        @include media-breakpoint-up(md) {
          margin-right: 8px;
        }
      }
      &:deep(.hay-button__slot--default) {
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
    &--place-ad {
      --button-background-color: #{color(neutral, 850)};
      --button-hover-background-color: #{color(neutral, 250)};
      --button-color: #{color(neutral, 100)};
      --button-padding: 0 10px;
      &:deep(.hay-button__slot--icon-start) {
        @include media-breakpoint-up(sm) {
          display: block;
        }

        display: none;
      }
    }
  }
}
</style>
