import { format, formatDistance, parse } from 'date-fns';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      shortDate: (date: number) => date && format(date * 1000, 'd MMM y'),
      formatTimeAgo: (date: number): string =>
        date ? `${formatDistance(new Date(date * 1000), new Date())} ago` : '',
      formatTimeRemaining: (date: number): string => {
        return formatDistance(new Date(), new Date(date * 1000));
      },
      parseTime: (time: string) => parse(time, 'HH:mm', new Date()),
      formatTime: (time: string) =>
        format(parse(time, 'HH:mm', new Date()), 'HH:mm'),
      formatHours: (open: string, close: string) => {
        const { $formatTime } = useNuxtApp();

        return `${$formatTime(open)}&ndash;${$formatTime(close)}`;
      },
      today: () => format(new Date(), 'EEEE').toLowerCase(),
    },
  };
});
