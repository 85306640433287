import { camelizeKeys, decamelizeKeys } from '@/utils/lodash';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', () => {
    const { $api } = useNuxtApp();

    $api.interceptors.request.use({
      success: (config: any) => {
        if (config.data) {
          config.data = decamelizeKeys(config.data);
        }

        return config;
      },
    });

    $api.interceptors.response.use({
      success: (response: any) => {
        return camelizeKeys(response);
      },
    });

    useHead({
      meta: [{ name: 'commit-sha', content: useEnv('GIT_COMMIT') }],
    });
  });
});
