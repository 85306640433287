import HttpFactory from '@/repository/factory';

class PostsModule extends HttpFactory {
  private ENDPOINT = useEnv('CF_SEARCH_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'adverts';
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async getAll({ params = {}, options: opts = {} } = {}) {
    const defaults = {
      page: 1,
      perPage: 24,
      sortBy: 'date',
      sortOrder: 'desc',
      advertType: 'both',
      sellerType: 'both',
    };

    const url = this.BASEURL;
    const options = { ...opts, params: { ...defaults, ...params } };

    return await this.call({ url, options });
  }

  async getBySlug({ slug = '', params = {}, options: opts = {} }) {
    const url = `${this.BASEURL}/${slug}`;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }

  async getRecent({ params = {}, options: opts = {} } = {}) {
    const url = `${this.BASEURL}/recent`;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }

  async getCount({ params = {}, options: opts = {} } = {}) {
    const url = `${this.BASEURL}/count`;
    const options = { ...opts, params };

    return await this.call({ url, options });
  }

  async getByCompany({ slug = '', params = {}, options: opts = {} } = {}) {
    const defaults = {
      page: 1,
      perPage: 24,
      sortBy: 'date',
      sortOrder: 'desc',
    };

    const url = `${this.BASEURL}/company/${slug}`;
    const options = { ...opts, params: { ...defaults, ...params } };

    return await this.call({ url, options });
  }
}

export { PostsModule };
