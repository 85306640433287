<script setup lang="ts">
const props = defineProps<{
  error: string | Record<string, any>;
}>();

const home = () => {
  clearError({ redirect: '/' });
};

const message = computed(() => props.error.message || props.error.value);

const isDevEnvironment = computed(() => import.meta.env.DEV);
</script>

<template>
  <div v-if="error" class="error-message">
    <h1 class="error-message__title">Error</h1>

    <p class="error-message__description">
      This page doesn't seem to be working correctly right now. An email has
      been sent to the development team.
    </p>
    <pre v-if="isDevEnvironment" class="error-message__body">{{ message }}</pre>
    <p class="error-message__footer">
      In the meantime,
      <a class="error-message__link" @click="home">you can click here</a>
      to head back to the home page.
    </p>
  </div>
</template>

<style scoped lang="scss">
.error-message {
  background: #ffffff;

  padding: 24px;
  &__title {
    color: color(primary, 700);

    margin: 0;

    font-size: 32px;
    font-weight: normal;
  }

  &__description {
    margin-top: 24px;
  }
  &__body {
    white-space: pre-wrap;

    background: #efefef;
    border-left: 5px solid #d8d8d8;

    margin-top: 16px;
    padding: 15px;

    font-size: 11px;
    font-weight: 700;
    line-height: 1.4;
  }
  &__footer {
    margin-top: 30px;
  }
  &__link {
    cursor: pointer;
    text-decoration: underline;

    color: color(primary, 750);
  }
}
</style>
