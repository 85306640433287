import { createQueryKeys } from '@lukemorales/query-key-factory';

const usePaymentsQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('payments', {
    all: (params = {} as Ref, returns = 'payments') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.user
          .getPayments({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { usePaymentsQueries };
