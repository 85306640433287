import HttpFactory from '@/repository/factory';

class PaymentLinksModule extends HttpFactory {
  private ENDPOINT = useEnv('CF_PAYMENT_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'payment-links';
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async get({ params = {}, options: opts = {} }) {
    let url = this.BASEURL;

    const options = { ...opts, params };

    return await this.call({ url, options });
  }
}

export { PaymentLinksModule };
