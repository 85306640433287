import { createQueryKeys } from '@lukemorales/query-key-factory';

const useNotificationsQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('notifications', {
    getAll: (category, params = {} as Ref, returns = 'notifications') => ({
      queryKey: [category, params, returns],
      queryFn: (context) =>
        $api.notifications
          .getAll({
            slug: toValue(category),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useNotificationsQueries };
