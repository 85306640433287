import validate from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45auth_45global from "/home/runner/work/haystack-nuxt/haystack-nuxt/middleware/0-auth.global.ts";
import canonical_45global from "/home/runner/work/haystack-nuxt/haystack-nuxt/middleware/canonical.global.ts";
import messages_45global from "/home/runner/work/haystack-nuxt/haystack-nuxt/middleware/messages.global.ts";
import manifest_45route_45rule from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45auth_45global,
  canonical_45global,
  messages_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/middleware/auth.ts"),
  "reset-password": () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/middleware/resetPassword.ts"),
  "verify-email": () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/middleware/verifyEmail.ts")
}