import HttpFactory from '@/repository/factory';

class ClicksModule extends HttpFactory {
  private BASEURL =
    'https://haystack-ad-click-api-staging.agriland.workers.dev';

  async register({ uuid = '', params = {}, options: opts = {} }) {
    const url = this.BASEURL;
    const data = { uuid };
    const method = 'POST';
    const options = { ...opts, params };

    return await this.call({ url, method, data, options });
  }
}

export { ClicksModule };
