import { Device } from '@capacitor/device';

const useDeviceType = async () => {
  const isApp = import.meta.client
    ? await Device.getInfo().then(
        (info) => info.platform === 'android' || info.platform === 'ios'
      )
    : false;

  return {
    isApp,
  };
};

export { useDeviceType };
