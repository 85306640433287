import OneSignal from 'onesignal-cordova-plugin';
import { Device } from '@capacitor/device';
import { useRouter } from 'vue-router';

export default defineNuxtPlugin(() => {
  const router = useRouter();

  /**
   * Converts a URL to a Vue Router route object.
   *
   * @param {string} url - The URL to be converted.
   * @returns {object} - A Vue Router route object representing the URL.
   */
  const urlToRoute = (url) => {
    const path = new URL(url).pathname;
    console.log('pathname is ' + path);
    const query = [...new URLSearchParams(url.split('?')[1])].reduce(
      (a, [k, v]) => ((a[k] = v), a),
      {}
    );

    return {
      path,
      ...(!!Object.keys(query).length && { query }),
    };
  };

  // Call this function when your app starts
  function OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId('628a2b76-a9fe-44c5-8d9e-de2fc892a50d');
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      console.log(
        'notificationOpenedCallback: ' + jsonData.notification.launchURL
      );
      console.log('testing functionality update v1.0');
      router.push(urlToRoute(jsonData.notification.launchURL));
    });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log('User accepted notifications: ' + accepted);
    });
  }

  Device.getInfo().then((info) => {
    if (info.platform !== 'web') {
      OneSignalInit();
    }
  });
});
