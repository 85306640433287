import type {
  DehydratedState,
  VueQueryPluginOptions,
} from '@tanstack/vue-query';

import {
  VueQueryPlugin,
  QueryClient,
  hydrate,
  dehydrate,
} from '@tanstack/vue-query';

import { useState } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query');

  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 5000 } },
  });

  const options: VueQueryPluginOptions = { queryClient };

  nuxtApp.vueApp.use(VueQueryPlugin, options);

  if (import.meta.server) {
    nuxtApp.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient);
    });
  }

  if (import.meta.client) {
    nuxtApp.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value);
    });
  }

  const filterResponse = (
    data: Record<string, any>,
    keys?: string | [string] | undefined
  ) => {
    if (!keys) {
      return data;
    }

    const keysIsArray = Array.isArray(keys);

    if (!keysIsArray) {
      const nested = keys.split('.');
      if (nested.length > 1) {
        let ret = data;

        nested.forEach((key) => {
          ret = ret[key];
        });

        return ret;
      }
    }

    const filtered = Object.keys(data)
      .filter((key) => (keysIsArray ? keys : [keys]).includes(key))
      .reduce(
        (acc, curr) =>
          Object.assign(acc, {
            [curr]: data[curr],
          }),
        {}
      );

    return keysIsArray ? filtered : filtered[keys];
  };

  return {
    provide: {
      filterResponse,
    },
  };
});
