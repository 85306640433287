import { createQueryKeys } from '@lukemorales/query-key-factory';

const useCompaniesQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('companies', {
    byUuid: (uuid, params = {} as Ref, returns = 'company') => ({
      queryKey: [uuid, params, returns],
      queryFn: (context) =>
        $api.companies
          .getByUuid({
            uuid: toValue(uuid),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    bySlug: (slug, params = {} as Ref, returns = 'company') => ({
      queryKey: [slug, params, returns],
      queryFn: (context) =>
        $api.companies
          .getBySlug({
            slug: toValue(slug),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useCompaniesQueries };
