export default defineNuxtRouteMiddleware(async (to, from) => {
  useHead(() => ({
    link: [
      {
        rel: 'canonical',
        href: 'https://www.haystack.ie' + to.path,
      },
    ],
  }));
});
