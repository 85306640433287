<script setup lang="ts">
const props = defineProps<{
  menuToggled: boolean;
  menuItems: Collection<Category>;
}>();

const emit = defineEmits<{
  (event: 'toggleMenu'): void;
}>();

const { logEvent } = useAnalytics();
const router = useRouter();

const doAction = (menuItem) => {
  emit('toggleMenu');

  if (menuItem.action) {
    menuItem.action();
  } else {
    router.push(menuItem.slug);
  }

  logEvent('click', { action: menuItem.name });

  return;
};
const version = useEnv('APP_VERSION');
const ssr = useEnv('SSR');
</script>

<template>
  <div class="the-menu">
    <Transition name="overlay">
      <div
        v-if="menuToggled"
        class="the-menu__overlay"
        @click="emit('toggleMenu')"
      />
    </Transition>
    <Transition name="menu">
      <nav v-if="menuToggled" class="the-menu__menu">
        <!-- <button title="Close (Esc)" type="button" class="wb-tax-calculator__close" @click="toggleModal">×</button> -->
        <ul>
          <li v-for="(menuItem, index) in menuItems" :key="index">
            <button
              v-if="menuItem"
              class="the-menu-item"
              @click="doAction(menuItem)"
            >
              <div class="the-menu-item__icon">
                <BaseSvgIcon type="mdi" :is="menuItem.icon" />
              </div>
              <div class="">
                {{ menuItem.name }}
              </div>
            </button>
          </li>
        </ul>
        <p class="text-xs ml-5 absolute px-3 bottom-0 right-0">
          Haystack v.{{ version }}
          <template v-if="ssr">[SSR]</template>
        </p>
      </nav>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.the-menu {
  $this: &;

  &__overlay,
  &__menu {
    @include media-breakpoint-up(md) {
      top: calc(var(--hay-safe-area-top) + 64px);
    }
    width: 100%;

    bottom: 0;
    position: fixed;
    right: 0;
    top: calc(var(--hay-safe-area-top) + 52px);
  }
  &__overlay {
    background: color(neutral, 850, 50%);

    z-index: 10;
    &.overlay-enter-from,
    &.overlay-leave-to {
      opacity: 0;
    }

    &.overlay-enter-to,
    &.overlay-leave-from {
      opacity: 1;
    }

    &.overlay-enter-active {
      transition: 300ms ease opacity;
    }
    &.overlay-leave-active {
      transition: 300ms ease opacity 300ms;
    }
  }
  &__menu {
    background: color(primary, 550);

    max-width: 306px;
    padding-left: 16px;
    padding-right: 16px;
    width: 85%;

    z-index: 20;
    &.menu-enter-from,
    &.menu-leave-to {
      transform: translateX(100%);

      opacity: 0;

      visibility: hidden;
    }

    &.menu-enter-to,
    &.menu-leave-from {
      transform: translateX(0);

      opacity: 1;

      visibility: visible;
    }

    &.menu-enter-active {
      transition: 300ms ease opacity 300ms, 300ms ease visibility 300ms,
        300ms ease transform 300ms;
    }
    &.menu-leave-active {
      transition: 300ms ease opacity 0s, 300ms ease visibility 300ms,
        300ms ease transform 0s;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    list-style-type: none;
    li {
      & + li {
        border-top: 1px solid color(primary, 400);
      }
      a {
      }
    }
  }
}

.the-menu-item {
  cursor: pointer;
  text-decoration: none;
  transition: 300ms ease background;

  background: none;
  border: none;
  color: color(neutral, 850);

  padding: 18px 12px;
  width: 100%;

  display: flex;

  font-size: 16px;
  line-height: 1.2;

  --webkit-appearance: none;
  align-items: center;
  &:hover {
    background-color: color(primary, 400);
  }
  &__icon {
    height: 24px;
    margin-right: 8px;
  }
}
</style>
