<script setup lang="ts">
const slots = useSlots();
</script>

<template>
  <div class="hay-form-title">
    <h2 class="hay-form-title__title"><slot /></h2>
    <h3 v-if="slots.subtitle" class="hay-form-title__subtitle">
      <slot name="subtitle" />
    </h3>
  </div>
</template>

<style scoped lang="scss">
.hay-form-title {
  display: flex;
  flex-direction: column;
  &__title,
  &__subtitle {
    color: color(neutral, 800);

    margin: 0;
  }
  &__title {
    font-size: 32px;
    font-weight: $bold;
  }
  &__subtitle {
    font-weight: $medium;
    font-size: 20px;
    margin-top: 5px;
  }
}
</style>
