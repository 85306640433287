import { object, string } from 'yup';

const loginFields = {
  email: string,
  password: string,
};

const loginSchema = object({
  email: string()
    .email('Please enter a valid email address')
    .required('This field is required'),
  password: string().required('This field is required'),
});

export { loginFields, loginSchema };
